.agreement-user-list {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 0;
}

.agreement-user-list:not(:last-of-type) {
    border-bottom: 1px solid #eee;
}

// .agreement-user-list:last-of-type {
//     padding-bottom: 0;
// }
// todo push to main
.no-gutters {
    padding: 0 !important;
    margin: 0 !important;
}

.no-gutters-button {
    margin: 0 !important;
}