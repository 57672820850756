.tender-view-overview-wrapper {
    a {
        cursor: pointer;
        color: #000;
    }
    .row {
        margin: 0;
        &.top-fields {
            margin: 10px 0 0;
        }
        .customer {
            text-decoration: underline;
            cursor: pointer;
            font-size: large;
            &:hover {
                color: $text-cyan;
            }
        }
        &.buttons {
            margin: 20px 0;
            .publish {
                padding-left: 0;
            }
            #edit-tender-btn {
                margin-left: 20px;
            }
        }
    }
    label {
        font-weight: normal;
        cursor: pointer;
    }
    .input-icon {
        position: absolute;
        right: 30px;
        top: 25px;
    }
    .form-control {
        padding: 20px 10px;
        height: auto;
        font-size: 18px;
        box-shadow: none;
        border: none;
        @media (max-width: $content-md-width) {
            padding: 10px;
            min-height: 20px;
            font-size: 16px;
        }
    }
    .form-control.date {
        min-height: 66px;
        text-align: right;
        padding-right: 15%;
        @media (max-width: $content-md-width) {
            min-height: 20px;
        }
    }
    .list {
        .row {
            padding: 20px 0px;
            border-bottom: 1px solid #eaebeb;
            .version {
                .title {
                    text-decoration: line-through;
                }
            }
            .title {
                font-size: 20px;
                color: $text-dark-gray;
                a {
                    color: $text-dark-gray;
                }
            }
            .details {
                font-size: 14px;
                &.modified {
                    color: $text-carrot;
                }
            }
        }
    }
}

.tender-nav {
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    &__item {
        padding-right: 15px;
        cursor: pointer;
        font-size: 13px;
        text-decoration: underline;
        color: #999999;
        &:hover {
            text-decoration: none;
        }
    }
}

.tender-info,
.tender-lots,
.tender-periods,
.tender-company-info {
    margin-top: 20px;
    margin-right: 16px;
    @media (max-width: $content-md-width) {
        margin-right: 0px;
    }
}

.tender-info__name {
    font-size: 30px;
    line-height: 36px;
    color: #333333;
    font-weight: 700;
    @media (max-width: $content-md-width) {
        font-size: 23px;
        line-height: 28px;
    }
}

.procuring-entity-brief {
    margin: 10px 0;
    &__title {
        @extend .block-info__title;
    }
    &__body {
        border: 2px solid #3faa98;
        padding: 10px;
        margin: 3px 0 0;
        .name {
            font-size: 16px;
        }
        .green {
            color: #3faa98;
        }
        a {
            color: #3faa98;
            text-decoration: underline;
            font-weight: 600;
        }
    }
}

.tender-budget {
    margin-top: 30px;
    @media (max-width: $content-md-width) {
        margin-top: 0px;
    }
    &__ammount {
        border-left: 5px solid #3faa98;
        background-color: #eef7f4;
        padding: 20px 10px;
    }
    &__minimal-step,
    &__guarantee {
        padding-left: 15px;
        @media (max-width: $content-md-width) {
            margin-top: 5px !important;
            border-left: 4px solid #9e9e9e;
        }
    }
    &__tariff {
        color: $text-light-gray;
        font-size: 14px;
        font-weight: normal;
        &--amount {
            color: $text-dark-gray;
            font-weight: bold;
        }
    }
}

.block-info {
    margin-top: 15px;
    &__title {
        font-weight: 300;
        font-size: 14px;
        color: #606060;
        line-height: 18px;
    }
    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            padding-left: 8px;
            border-left: 1px solid #a2a2a2;
            font-size: 13px;
            margin-top: 8px;
            dl {
                margin-bottom: 5px;
            }
            dt {
                color: #676767;
                font-weight: normal;
            }
        }
    }
    &__text {
        color: #333333;
        font-size: 16px;
        word-break: break-all;
        a {
            color: #3FAA98;
            text-decoration: underline;
        }
        a:hover {
            text-decoration: none;
        }
        .requirements {
            margin-left: 10px;
            .requirements-title {
                font-size: 12px;
                .requirement-status {
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 1px 4px;
                    margin-right: 5px;
                    &.active {
                        background-color: bisque;
                    }
                    &.cancelled {
                        background-color: rgb(243, 233, 221);
                    }
                }
            }
            .requirements-fields {
                border-left: 3px solid #cccccc75;
                padding-left: 10px;
            }
            &.cancelled {
                opacity: 0.7;
            }
        }
    }
    &__text--bold {
        font-weight: 700;
    }
    &__text--small {
        font-size: 14px;
    }
    &__text--big {
        font-size: 19px;
    }
    &__text--large {
        font-size: 22px;
    }
    &_req {
        padding-top: 16px;
        button {
            margin-left: 0;
        }
    }
}

.tender-documents__all-docs {
    text-align: right;
    a {
        color: #3FAA98;
        font-weight: bold;
    }
}

.short-lots__list {
    padding: 10px;
    margin: 0;
    list-style: none;
    @media (max-width: $content-md-width) {
        max-width: 300px;
    }
}

.short-lots__item {
    padding: 10px;
    border-bottom: 1px solid #dedede;
    outline: none;
    cursor: default;
    &:last-child {
        border-bottom: none;
    }
}

.short-lot__description,
.short-lot__title {
    width: 350px;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    @media (max-width: $content-md-width) {
        width: auto;
    }
}

.short-lot__title {
    color: #3FAA98;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}

.short-lot__budget {
    width: 200px;
    text-align: center;
    font-weight: bold;
    @media (max-width: $content-md-width) {
        text-align: left;
    }
}

.short-lot__go-btn {
    background-color: #3faa98;
    font-size: 13px;
    font-weight: 400;
    color: #FFFFFF;
    padding: 3px 6px;
    cursor: pointer;
}

.tender-lots__item {
    margin-top: 20px;
}

.lot-item__description {
    margin-top: 25px;
}

.lot-item__budget {
    margin-top: 20px;
}

.tender-lots__list {
    padding: 0;
    margin: 70px 0 0 0;
    list-style: none;
}

.tender-block__title {
    font-size: 22px;
    line-height: 32px;
    color: #3FAA98;
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #dcdcdc;
    @media (max-width: $content-md-width) {
        font-size: 19px;
        padding: 0 0 3px 0;
        line-height: 27px;
    }
}

.tender-block__title--bold {
    font-weight: 700;
}

.lot-item__delivery {

}

.lot-item__delivery--start {
    background-color: #eef7f4;
}

.tender__features-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tender-feature {
    margin-top: 10px;
    &__title {
        font-weight: 500;
    }
    &__table {
        border-color: #fff !important;
        margin-top: 5px;
        margin-bottom: 0px;
        thead td {
            background: #eef7f4;
            border-color: #fff !important;
            width: 70%
        }
        tbody td {
            border-color: #eef7f4 !important;
            width: 30%;
            &.feature-table__value {
                text-align: center;
            }
        }
        .feature-table__title {
            font-weight: 500;
        }
    }
}

.tender-periods {
    &__item {
        padding: 10px 0px;
        @media (max-width: $content-md-width) {
            padding: 0px;
            .block-info__text {
                padding-left: 0px !important;
                font-size: 14px;
            }
        }
    }
    &__item--current {
        border-left: 5px solid #3faa98;
        padding: 10px 20px;
    }
}

.tender-lots {
    margin-top: 25px;
    &__toggle-all {
        display: inline-block;
        cursor: pointer;
        outline: none;
    }
    &__toggle-all i {
        display: inline-block !important;
        padding: 4px;
        background-color: #3faa98;
        color: #fff;
    }
    &__toggle-all span {
        display: inline-block;
        border-bottom: 1px dashed #3faa98;
        font-size: 16px;
        color: #3FAA98;
        margin-left: 3px;
    }
    &__toggle-all:hover span {
        display: inline-block;
        border-bottom-color: transparent;
    }
    .tender-milestone {
        margin: 0;
        justify-content: space-between;
        word-wrap: break-word;
        &>div {
            flex-basis: 25%;
        }
        .block-info {
            margin: 8px;
            &__text {
                word-break: break-word;
            }
        }
    }
    .tender-milestone-description {
        margin: 0;
        .block-info {
            margin: 8px;
            &__text {
                word-break: break-word;
            }
        }
    }
}