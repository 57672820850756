// provider-specific values
$header-bg-color: #5ab9ad;
$icon-font-path: "../../node_modules/bootstrap-sass/assets/fonts/bootstrap/";

$status-badge-bg: white;
$status-badge-color: $header-bg-color;

// links
$active-state-color: #6fbfb2;

// loading bar
$loading-bar-color: darken($header-bg-color, 10%);
// + #202020;

//badge
$font-size-small: 14px;
$badge-color: #636363;
$badge-bg: #f4f4f4;