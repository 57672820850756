@import "tender-view.chat";
@import "tender-view.chat.modal";
@import "tender-view.overview";
@import "tender-view.documents";
@import "tender-view.auction";
@import "tender-view.bid";
@import "tender-view.invites";
@import "tender-view.contracts";
@import "tender-view.prequalification";
@import "../customer-modal";

.tender-wrapper {
    .header {
        .center {
            margin-left: auto;
        }

        .type {
            color: $status-badge-color;
        }

        .status {
            background-color: white;
            color: #3faa98;
            font-size: 11px;
            border-radius: 2px;
            padding: 7px 10px;
            text-transform: uppercase;
            line-height: 30px;
            margin: auto 2px;
            white-space:    nowrap;
            @media (max-width: $content-md-width) {
                font-size: 10px;
                line-height: 10px;
                padding: 2px;
                margin: 0;
                letter-spacing: -0.5px;
            }
            @media (max-width: $content-lg-width) {
                font-size: 11px;
                line-height: 10px;
                padding: 2px;
                margin: 0;
                letter-spacing: -0.2px;
            }

            &.closed {
                color: $text-color;
            }
        }

        .status--blocked {
            margin-right: 6px;
            color: #ffffff;
            background-color: #aa3f3f;
        }
    }

    .unavailable {
        text-decoration: line-through;
    }

    .status {
        cursor: default;
    }
}

.tender-bid-place {
    margin: 0.5em 0 0.5em 0;

    @media (max-width: $content-md-width) {
        text-align: center;
        margin-top: 20px;
    }

    &__button {
        margin: 0.5em 0;
    }
}

.edit-btns {
    margin-top: 8px;
}

.company-bid-placed {
    text-align: center;
    color: #ffffff;
    margin: 1em 0 0.5em;
    padding: 1em;
    background-color: #ff8a80;
}

.lot-info {
    padding: 10px 0;

    &-item {
        white-space: nowrap;
    }
}

.badge {
    position: absolute;
    right: -7px;
    top: -7px;
    color: #494949;
    border: 1px solid #cccccc;
    border-radius: 20%;
    padding: 4px;
    background-color: rgba(204, 204, 204, 0.5);
    box-shadow: 2px 2px 5px #cccccc; 
}