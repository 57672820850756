.tender-view-documents-wrapper {
  margin-top: 0;

  &.my-documents {
    .list {
      @media (max-width: $content-md-width) {
        margin-top: 0px;
      }
      &__title {
        font-size: 16px;
        color: #9c9c9c;
        font-weight: normal;
        margin: 15px 0 0;
      }
    }
  }

  .list {
    margin-top: 0;
  }
}