.notifications-bell {
  position: relative;
  top: 7px;
  @media (max-width: $content-md-width) {
    top: 3px;
    font-size: 13px;
  }
  &:hover, &:focus {
    color: white;
    text-decoration: none;
  }
    &__badge {
      position: absolute;
      font-size: 10px;
      background: red;
      color: white;
      top: -7px;
      left: -7px;
      text-align: center;
      width: 19px;
      height: 19px;
      padding: 3px 0px 0 0px;
      border-radius: 50%;
      @media (max-width: $content-md-width) {
          width: 17px;
          height: 17px;
          font-size: 8px;
          left: -12px;
      }
    }
}

.notification-preview {
  cursor: pointer;
  font-size: 14px;
  padding: 13px;
  border-bottom: 1px solid #e8e8e8;
    &__date {
      font-size: 12px !important;
      color: #7b7b7b;
      .notification-role{
        color: #3faa98;
      }
      .notification-name{
        color: #3faa98;
        text-decoration: underline;
        font-weight: bold;
      }
    }
    &__title {
      font-weight: bold;
      color: #636363;
      font-size: 16px;
    }
}

.notification-full {
  position: absolute;
  top: -28px;
  left: 200px;
  right: -8px;
  box-shadow: 0 0 10px #777777;
  background: white;
  padding: 20px;
  z-index: 25;
  min-height: 100px;
  @media (max-width: $content-md-width) {
    left: auto;
    width: 280px;
  }
  &--bottom {
    top: auto;
    bottom: 0px;
  }
    &__title {
      font-weight: bold;
      font-size: 16px;
    }
    &__link {
      text-align: center;
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #eaeaea;
    }
    &__date {
      font-size: 13px;
      color: #8c8c8c;
    }
    &__text {
      margin: 7px 0;
      font-size: 14px;
    }

}

.notification {
  position: relative;
  &--not-readed {
    background: #e7f0ed;
    z-index: 1;
  }
  &:hover &__preview {
    box-shadow: 1px 1px 3px #585858;
    border-left: 6px solid #3faa98;
    background: #f7fffe;
    padding: 13px 10px;
  }
  &:hover &__menu {
    opacity: 1;
  }
  &:active {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
  &:hover {
    z-index: 2;
  }
  &:not(.notification--not-readed):hover{
    overflow: visible;
    height: 67px;
    .hide {
      display: block !important;
    }
  }
    &__menu {
      position: absolute;
      top: 5px;
      right: 0;
      opacity: 0;
    }
    &__message {
      position: relative;
    }
}

.search-filter {
  .title {
    padding: 5px;
    color: #3faa98;
    margin: 0;
  }
  .body {
    border: 2px solid #3faa98;
    padding: 20px 10px 10px;
    md-input-container {
      margin: 30px 0 10px !important;
      width: 100% !important;
    }
    .md-datepicker-input {
      min-width: 150px !important;
    }
  }
}