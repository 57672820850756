.invite-wrapper {
  margin-top: 70px;

  .provider-field {
    width: 81.5%;
    display: inline-block;
  }

  .invites-list {
    margin-top: 30px;

    .item {

      label {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .checked {
        color: #3da997;
      }

      .succefull {
        background-image: url(/static/images/checked.svg);
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        width: 40px;
        height: 40px;
      }

      .unsuccesfull {
        background-image: url(/static/images/unchecked.svg);
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        width: 40px;
        height: 40px;
      }

      .close-cross-black {
        position: relative;
        bottom: 4px;
      }

      .company-name {
        min-width: 41.66667%;
      }
    }
  }

  .dropdown {
    display: block;

    .dropdown-menu {
      width: 81.5%;
    }
  }

  [type="checkbox"] {
    height: 100%;
  }
}