.tender-view-auction-wrapper {
    @media (max-width: $content-md-width) {
        margin: 0 15px;
    }

    h4 {
        color: #3faa98;
    }

    .label-field {
        color: #3faa98;
        font-weight: bold;
    }

    hr {
        border: none;
        border-bottom: 1px solid #f0f1f1;
        margin-top: 30px !important;
    }

    .time-block {
        .row {
            margin-top: 15px;
        }
    }

    .md-button.md-raised:not([disabled]) {
        min-width: 160px;
    }

    .cancellation-block {
        padding-top: 15px;

        h4 {
            color: #ba4f4f;
            font-weight: bold;
        }


        .row {
            margin-top: 15px;
        }

        .list {

            .row {
                margin-top: 0;
            }

            .col-xs-2 {
                padding: 0;

                a {
                    margin-top: 10px;
                }
            }

            .btn {
                padding-top: 15px;
            }

            .col-sm-8 {
                margin-top: 5px;

                a {
                    color: black;
                    font-size: 16px;
                }
            }
        }

    }

    .auction-link-block {

        a {
            display: inline-block;
            margin: 25px 15px;
            text-decoration: underline;
        }
    }

    .auction-members-block {
        margin-bottom: 35px;

        .row {
            background-color: #f4f4f4;
            padding: 20px;
            border-top: 1px solid #e1e1e1;
            cursor: pointer;

            .active {
                color: #83c6bb;
            }

            .canceled {
                color: #c76a6a;
            }

            .default {
                color: #000;
            }

            &:last-child {
                border-bottom: 1px solid #e1e1e1;
            }

            .price {
                font-size: 28px;
                font-weight: bold;
                color: #000;
            }

            .name {
                font-size: 18px;
                text-decoration: underline;
                color: #000;
            }
        }
    }

    .contract-wrapper {

        .contract-btn-wrapper {
            margin-top: 30px;

            .tilt {
                color: #999;
                font-size: 12px;
            }

            .tender-winner {
                color: #000;
                text-decoration: underline;
            }
        }

        .list {
            margin-top: 30px;

            .row {
                padding: 20px 0px;
                border-top: 1px solid #eaebeb;

                .version {

                    .title {
                        text-decoration: line-through;
                    }


                }

                .title {
                    font-size: 20px;
                    color: $text-dark-gray;

                    a {
                        color: $text-dark-gray;
                    }
                }

                .details {
                    font-size: 14px;

                    &.modified {
                        color: $text-carrot;
                    }
                }
            }
        }

        a.btn-square {
            padding-top: 15px;
        }
    }

    .tender-auction__lot {
        border: 1px solid #d4d4d4;
        border-bottom: none;
        height: auto;
        padding: 8px 0;

        &:hover {
            background: #dff3ef;
        }

        &:last-child {
            border-bottom: 1px solid #d4d4d4;
        }

        &--active {
            background: #dff3ef;
            box-shadow: inset 0 0 7px #97bdb6;
        }
    }

}