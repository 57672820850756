.container-fluid {
    >.row {
        width: 100%;
    }
}

.content {
    @include display-flex();
    @include flex(1);
    background-color: white;
    height: 100%;
    padding-left: 40px;
    padding-right: 45px;
    overflow: auto;
    opacity: 1;
}

.flex-wrap {
    @include display-flex();
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.content-wrapper {
    @include flex-direction(column);
    @include display-flex();
    @include flex(1);
}

.main {
    background-color: white;
    min-height: -webkit-min-content;
    min-height: min-content;
    min-height: -moz-min-content;
    @include display-flex();
    @include flex-grow(1);
    z-index: 1000;
}