.btn {
  @extend .btn-default;

  &.rounded {
    border-radius: 2px;
  }
}

.btn-lg {
  padding: 17.5px 30px;
}

.btn-square {
  @include button-variant(#fff, $btn-square-bg, $btn-square-bg);
  border: none;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $btn-square-bg-hover;
  }
}
.close-btn {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 11px;
  cursor: pointer;

  @extend .icon-red;
  @extend .close-red;
}
.btn-icons {
    background: #3F4952;
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    padding: 5px;
    .text {
        width: 100%;
        line-height: 1.2;
        text-align: left;
    }
    img {
        margin: 5px  5px;
    }
}
.jur-consult {
    margin-top: 15px;
}
.btn-jur-consult {
    width: 100%;
    background-color: #3F4952 !important;
}
.calendar-btn {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 24px;
  top: 17px;
  font-size: 16px;
  cursor: pointer;
  z-index: 10;

  @extend .icon-black;
  @extend .calendar;
}

.glyphicon.glyphicon-chevron-up {
  display: none;
}

.glyphicon.glyphicon-chevron-down {
  display: none;
}

.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  display: inline-block;
  position: absolute;
}

.checkbox {
  display: inline-block;
  position: relative;

  input[type="checkbox"] {
    opacity: 0;
    bottom: 4px;
    right: 0px;
    cursor: pointer;
    z-index: 5;
    height: 20px;
    width: 20px;
  }

  input[type="checkbox"]:not(:checked) + span {
    @include transition-duration(0s);
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #c1c1c1;
    position: relative;
    bottom: 4px;
    background-color: #fff;
    border-radius: 2px;

    &:before {
      background-image: none;
    }
  }

  input[type="checkbox"]:checked + span {
    @include transition-duration(0s);
    display: inline-block;
    width:20px;
    height:19px;
    position: relative;
    bottom: 5px;

    &:before {
      background-image: url("/static/images/spriteRed.svg");
      border-radius: 2px;
    }
  }
}

.modal-footer, .btn-wrapper {
  .md-button {
    margin: 0 !important;
  }
}
.btn-wrapper {
    .btn-wide {
        min-width: 220px;
    }
}
