.profile-wrapper {
  width: 80%;
  a {
    cursor: pointer;
    color: #000;
  }
  a.btn {
    color: #fff;
  }
  .row {
    margin: 0;
    .has-error .form-control {
      border-bottom: 2px solid $validation-border-color;
      background-color: $validation-background-color;
    }
    input,
    select {
      border: 2px solid #CACBCC;
      background-color: white;
    }
    input {
      padding: 20px 10px;
      height: 40px;
      border: solid 0px;
      font-size: 14px;
      background-color: #FAFAFA;
      border-radius: 2px;
      margin-right: 8px;
    }
    .query {
      min-width: 300px;
    }
    .customer {
      text-decoration: underline;
      cursor: pointer;
      font-size: large;
      &:hover {
        color: $text-cyan;
      }
    }
    &.top-fields {
      margin: 10px 0 0 0;
    }
    &.buttons {
      margin: 20px 0;
      .publish {
        padding-left: 0;
      }
      #edit-tender-btn {
        margin-left: 20px;
      }
    }
  }
  label {
    font-weight: normal;
    cursor: pointer;
  }
  .input-icon {
    position: absolute;
    right: 30px;
    top: 25px;
  }
  .form-control {
    // padding: 20px 10px;
    height: 60px;
    // font-size: 18px;
    box-shadow: none;
    // border: none;
  }
  .list {
    .row {
      padding: 20px 0px;
      border-top: 1px solid #eaebeb;
      .version {
        .title {
          text-decoration: line-through;
        }
      }
      // .title {
      //   font-size: 20px;
      //   color: $text-dark-gray;
      //   a {
      //     color: $text-dark-gray;
      //   }
      // }
      .details {
        font-size: 14px;
        &.modified {
          color: $text-carrot;
        }
      }
    }
  }
  .classifier-subscription{

    margin: 0;
    .classifier-input {
      width: 100%;
      background-color: #eee;
      cursor: pointer;
      height: 60px;
      padding: 6px 12px;
    }
    label {
      padding-top: 10px;
      cursor: pointer;
      font-weight: normal;
      padding-right: 8px;
    }
  }
  .tenders-list .list .item-row .left .caption, .items-list .list .item-row .left .caption{
    width: 200px
  }
  .new-subscription{
    .chip-list{
      display: flex;
      flex-wrap: wrap;
      .chip-item{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        background: rgb(224,224,224);
        border-radius: 15px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 13px;
        .md-chip-content{
          margin-right: 5px;
          white-space: pre;
        }
        .md-chip-remove-container{
          button{
            padding: 0;
            background: transparent;
            border: none;
          }
        }
      }
    }
    .md-button{
      margin: 0
    }
    .md-chip-input-container{
      display: none;
    }
    .md-chips md-chip {

      .md-chip-remove-container{
        line-height: 16px;
      }
      .md-chip-remove {
        width: 20px;
        height: 20px;
        md-icon {
          min-height: 18px;
          min-width: 18px;
        }
      }
    }
  }


}

.verification-data {
padding-top: 5px;
&__list {
margin: 0;
padding: 0;
list-style: none;
li {
  margin-bottom: 3px;
  padding-left: 10px;
  border-left: 2px solid #7d7d7d;
  font-size: 12px;
}
}
&__info {
color: #5189a7;
font-size: 12px;
}
}

.user-contacts {
&__create {
display: block;
margin-top: 40px;
border-top: 1px solid #d4d4d4;
padding-top: 15px;
}
}

.create-contact {
&__button {
margin: 1px 0;
padding: 9px !important;
}
.layout-row md-input-container.md-input-focused,
.layout-row md-input-container.md-input-has-value {
margin-top: 0 !important;
}
}

.user-contact {
padding: 15px;
border-bottom: 1px solid #eaeaea;
min-height: 60px;
&__main {
md-checkbox {
  margin: 0;
}
}
&__email {
font-weight: bold;
margin-bottom: 8px;
}
&__buttons {
.md-button {
  padding: 0 !important;
  min-height: 25px;
  margin: 0 3px;
  min-width: 24px;
  font-size: 10px;
  opacity: 0.25;
  border-radius: 50% !important;
  transition: opacity 300ms;
}
}
&:hover {
.user-contact__buttons {
  .md-button {
    opacity: 1;
  }
}
}
&:last-child {
border-bottom: none;
}
}

.profile-login {
md-input-container {
padding-top: 16px;
vertical-align: top;
margin-top: 0;
}
input {
height: 48px;
}
button {
margin-top: 0;
height: 48px;
min-width: 200px;
}
form .error-message {
font-size: 13px;
color: #c10c0c;
margin: 0 0 15px 0;
}
.dialog-form__link a {
padding-left: 0px;
}
}

.short-subscription {
padding: 10px;
}
.profile-log {
md-input-container {
margin: 8px 0 !important;
}
.content-form md-input-container{
&.md-input-has-placeholder {
  margin: 8px 0 !important;

  &.md-input-has-value {
    margin: 8px 0 !important;
  }

  &.md-input-focused {
    margin: 8px 0 !important;
  }
}
&.md-input-has-value {
  margin: 8px 0 !important;
}

&.md-input-focused {
  margin: 8px 0 !important;
}
}
.md-primary {
color: $accent-color;
}
md-select{
background-color: #FFF;
}
a{
color: #337ab7
}

.search-query-container {
margin-top: 0;
margin-bottom: 0;
}

.search-query-button {
margin-top: 0;
margin-bottom: 0;
padding: 0 15px;
min-width: 80px;
&.md-primary {
  color: #fff;
}
}
.search{
margin: 0 auto 30px;

.query-clear {
  margin: 6px -40px 6px 0;
  min-width: 40px;
}

.md-errors-spacer {
  display: none;
}

.full-width {
  width: 100%;
}

md-block.md-small {
  padding: 0 8px !important;
  margin: 0;
}
input[type="text"]{
  background-color: #fff;
}

.md-button.md-small {
  padding: 0 8px;
  margin: 0;
}



md-datepicker {
  display: block;
}





padding: 20px 0;
background: #edf7f5;
.full-width{
  width: 100%;
}
md-datepicker {
  width: 100%;
  .md-datepicker-input-container{
    width: calc(100% - 64px);
  }
}
}
}