.legend{
  .legend_block{
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }
  .color{
    display: block;
    widtH: 30px;
    height: 15px;
    margin-right: 5px;
  }
}


