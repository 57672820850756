.answer-form {
  .title {
    height: 70px !important;
    border-bottom: 2px solid #20272D;
  }

  .form {
    height: 200px;
    padding: 25px;
  }

}