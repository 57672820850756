// fonts
@import "../../node_modules/compass-sass-mixins/lib/compass/_css3.sass";
@import "fonts";

$font-family-sans-serif: "Roboto","Helvetica Neue Light","Helvetica Neue","Helvetica","Arial","Lucida Grande",sans-serif;
$font-size-base: 14px;

// font colors
$text-color: #606060;
$text-light-gray: #999999;
$text-dark-gray: #333333;
$text-cyan: #3faa98;
$text-vinous: #993333;
$text-carrot: #c15656;
$text-almost-black: #20272d;
$text-lighter-gray: #ccc;

//inputs
$input-bg: #FAFAFA;
$input-border-radius: 0;
$input-border-bottom: #CACBCC;
$input-readonly-border-bottom: #cacbcc;
$input-height-base: 60px;
$validation-border-color: #ff0000;
$validation-focus-border-color: #f0c0c0;
$validation-background-color: #fff5f5;

//buttons
$btn-danger-color:#fff;
$btn-danger-bg: #c15656;
$btn-danger-border: #c15656;

$btn-default-color: #fff;
$btn-default-bg: #2e373f;
$btn-default-border: #2e373f;
$border-radius-base: 0;

$green-plus-btn-color: #3faa98;

$active-state-color: #3faa98;

//square button
$btn-square-bg: #20272d;
$btn-square-bg-hover: $text-cyan;

//btn-lg
$padding-large-vertical: 25px;
$padding-large-horizontal: 30px;
$border-radius-large: 0;

$status-badge-bg: white !default;

// loading bar
$loading-bar-color: $status-badge-bg !default;

// breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-color: $text-almost-black;
$breadcrumb-separator: ">";
$breadcrumb-default-color: white;
$breadcrumb-active-color: white;

// pagination
$pager-border-radius: 0px;
$pager-bg: $btn-square-bg;
$pager-hover-bg: $text-almost-black;
$pager-disabled-color: $text-light-gray;

$pagination-color: $text-almost-black;
$pagination-bg: white;
$pagination-border: none;

$pagination-hover-color: lighten($text-almost-black, 30%);
$pagination-hover-bg: white;

$pagination-disabled-color: white;
$pagination-disabled-bg: lighten($text-almost-black, 30%);

$pagination-active-color: #3faa98;
$pagination-active-bg: white;

// sidebar
$sidebar-bg: #20272d;
$sidebar-active-bg: #2e373f;

//cursor
$cursor-disabled: default;

//alerts
$alert-danger-bg: $btn-danger-bg;
$alert-danger-text: white;
$close-color: white;

// nagivation
$nav-link-padding: 5px 30px;