.planning-create-wrapper {
  width: 600px;

  .items-wrapper {
    position: relative;

    .short-description {

      label {
        position: relative;
        top: 10px;
      }
    }

    .remove-field {
      position: absolute;
      right: -25px;
      top: 60px;
      transition: transform  1s ease 0.5s;

      &:hover {
        transform: rotate(360deg);
      }

    }

    .add-field {
      position: absolute;
      right: -25px;
      top: 20px;
      transition: transform  1s ease 0.5s;

      &:hover {
        transform: rotate(-360deg);
      }
    }
  }

  .form-control[readonly] {
    cursor: pointer;
  }

  div.left {
    float: left;
  }

  div.right {
    float: right;
  }

  input.num {
    width: 120px;
  }

  a {
    cursor: pointer;
    color: #000;
    text-decoration: none;
  }

  .row {
    margin: 0;



    &.top-fields {
      margin: 10px 0 0 0;
    }

    &.buttons {
      margin: 20px 0;

    }

    .classifier-input {
      width: 446px;
      padding-right: 45px;
    }
    .kekv-input {
      width: 323px;
    }
    .classifier-field {
      display: inline-block;

      input {
        padding-right: 50px;
      }
    }

  }

  label {
    cursor: pointer;
    font-weight: normal;

    &.left {
      width: 120px;
    }
    &.left-wide {
      width: 270px;
    }

    &[for="with-nds"] {
      margin-left: 5px;
    }

    &[for="step"] {
      margin-right: 10px;
    }
  }

  .unit-dropdown {
    margin-left: 18px;
  }

  .input-icon {
    position: absolute;
    right: 30px;
    top: 25px;
  }

  .checkbox {
    margin-left: 18px;
  }

  [type='checkbox'] {
    height: 20px;
    width: 20px;
  }

  .form-inline .input-group > .form-control.date {
    width: 170px;
  }
  input.date {
    width: 170px;
  }

  .calendar-btn {
    z-index: 10;
    transform: scale(0.7);
  }

  .timepicker {
    height: $input-height-base;
    margin-left: 8px;

    table {
      width: 115px;
    }

    // hide arrows
    tbody tr:first-child, tbody tr:last-child {
      display: none;
    }

    td {
      background-color: $input-bg;
    }

    .form-control {
      width: 55px;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}