.subscription_regions {
  md-chip {
    font-size: 13px;
    line-height: 24px;
    height: 24px;
  }
}

.md-checkbox-comment {
    color: #9e9e9e;
}
