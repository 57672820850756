    body {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    font-smoothing: auto;
}

header {
    .logo {
        height: $header-height;
        text-align: center;
            a {
            height: $header-height;
            display: inline-block;
            width: 160px;
            background-image: url(/static/images/logo.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 160px 20px;
            cursor: pointer;

            @media (max-width: $content-md-width) {
                width: 180px;
                background-size: 180px;
            }

            @media (max-width: $content-sm-width) {
                width: 130px;
                background-size: 130px;
            }
        }
    }
}

.user-menu-btn {
    &__info {
        padding-right: 10px;
        text-align: left;
        line-height: 16px;
    }
    .name{

    }

    &__role {
        font-size: 11px;
        line-height: 11px;
        font-weight: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
    }
}

md-toolbar.header-toolbar {
    min-height: $header-height;
    background-color: #008c78 !important;
    position: fixed;
    top: 0;
    z-index: 1001;

    .md-toolbar-tools {
        height: $header-height;
        max-height: $header-height;
    }
    .md-button {
        min-width: 120px;
    }
    .menu-title {
        font-family: 'Roboto Bold', 'Roboto';
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
        color: #FFFFFF;
        text-transform: none;
    }

    .action-btns .md-button {
        text-transform: none;

        .glyphicon {
            font-size: 11px;
            margin-right: 3px;
        }

        &:hover,
        &:focus {
            color: #fff;
        }
    }
}

.left-sidenav {
    top: $header-height + 50px;
    position: fixed !important;
    width: $left-side-bar-width;
    left: 50%;
    margin-left: -$content-lg-width / 2;
    transition: left 0.5s;
    z-index: 75;

    @media (max-width: $content-lg-width) {
        margin-left: 0;
        left: 0;
    }

    @media (max-width: $content-md-width) {
        margin-left: 0;
        left: 0;
        top: $header-height;
        padding-top: 40px;
        width: $left-side-bar-width + $left-side-bar-toggle-btn-width;
        padding-right: $left-side-bar-toggle-btn-width;
        box-shadow: 0 0 4px grey;
        min-width: 0;
        max-width: 100%;
    }

    &__content {
        position: initial;
        min-height: 100%;
    }

    &__hide {
        display: none;
        background: #dedede;
        top: 0;
        bottom: 0;
        position: absolute;
        right: 0px;
        width: $left-side-bar-toggle-btn-width;
        cursor: pointer;
        outline: none;

        @media (max-width: $content-md-width) {
            display: flex;
            top: 0;
        }

        &:hover {
            background: #cae6e2;
        }

        .glyphicon-chevron-right {
            display: none;
        }
    }

    &.md-closed {
        display: flex;
        transform: translate3d(0, 0, 0);

        @media (max-width: $content-md-width) {
            left: -$left-side-bar-width;
        }

        .left-sidenav__hide {
            .glyphicon-chevron-right {
                display: inline-block;
            }

            .glyphicon-chevron-left {
                display: none;
            }
        }
    }


    .sidebar-status-title {
        background-color: #edf7f5;
        padding: 5px 20px;
        text-align: right;
        font-size: 16px;
        margin-top: 10px;
    }
    .main-sidebar {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 98px);
        .sidebar-tender{
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .side_links{
            flex: 1 0 auto
        }

        .nav {
            text-align: right;

            a {
                display: inline-block;
                font-weight: 400;
                display: block;
                padding: 5px 10px;
                background: none;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                text-align: right;
                text-decoration: underline;
                color: #333333;
                transition: all .15s ease;
                cursor: pointer;

                &:hover {
                    color: #318577;
                }

                &.active {
                    color: #222;
                    background-color: #dbeeeb;
                    text-decoration: none;
                }

                &.back-to-search {
                    color: #5bc0dd;
                }
            }
        }

        .bottom-block {
            margin-top: auto;
            //position: absolute;
            //bottom: 0;
            //bottom: 0;
            //left: 0px;
            //right: 15px;
        }
    }
}

md-content.main-content {
    position: relative;
    margin-top: 40px;
    margin-left: $left-side-bar-width;
    padding: 50px 0;

    @media (max-width: $content-md-width) {
        margin-left: 40px;
    }
}

section.content-wrapper {
    width: $content-lg-width;
    margin: 0 auto;

    @media (max-width: $content-lg-width) {
        width: 100%;
    }
}
// TODO check needed
.company-info {
    .md-toolbar-tools {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 991px) {
            display: none;
        }
        @media (min-width: 1280px) {
            display: none;
        }
        .name {
            font-size: 16px;
            color: #999999;
            margin-bottom: 0;
        }

        .expandCollapse {
            width: 30px;
            height: 30px;
            position: relative;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
            color: #fff;
            display: block;
            margin-top: -2px;
            margin-left: -2px;
            overflow: hidden;

            &:before, &:after {
                width: 30px;
                height: 30px;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                line-height: 32px;
                text-align: center;
                -webkit-transition: .3s all ease-out;
                transition: .3s all ease-out;
                color: #999999;
            }

            &:before {
                opacity: 1;
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                content: "|";
                margin-top: -3px;
            }

            &:after {
                opacity: 1;
                -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
                content: "|";
                margin-left: -3px;
            }
        }
    }
}
.company-info .info {
    padding: 0px 0px 15px 20px;
    color: #999999;

    a {
        text-decoration: underline;
        color: #66CC99;

        &:hover {
            text-decoration: none;
        }
        &.text-warning {
            color: #c15656;
            width: 200px;
            border: 1px solid #c15656;
            border-radius: 4px;
            padding: 5px;
            text-align: center;
            text-decoration: none;
            font-weight: 500;
        }
    }

    .phones-list {
        >div {
            font-size: 16px;
        }

        ul {
            margin: 5px 0 0 0;
            padding: 0;
        }

        li {
            color: #333333;
            font-size: 15px;
            text-align: left;
            font-weight: 700;
        }
    }

    .email {
        padding-top: 15px;

        a {
            font-size: 14px;
        }
    }

    .reglament {
        a {
            color: #999999;
        }
    }

    .languages {
        a {
            display: inline-block;
            margin-right: 2px;
        }
    }

    md-divider {
        margin: 10px 0px 10px -20px;
    }
}

.tooltip-tariffs {
    width: 260px;
    text-align: left !important;
    padding: 10px 20px !important;
}

.mb-0 {
    margin-bottom: 0;
}

.text-cyan {
    color: $text-cyan
}
