md-list.requests {
  .status {
    padding: 30px 15px 10px 0;
    font-size: 23px;
  }
  .request {
    .status i {
      display: none;
    }
    h3 {
      font-weight: bold !important;
    }
    .request-menu {
      padding-top: 15px;
      opacity: 0;
    }
    &:hover {
      .request-menu {
        opacity: 1;
        transition: opacity 800ms;
      }
    }

    &.request-confirm .status i.glyphicon-ok,
    &.request-reject .status i.glyphicon-remove,
    &.request-pending .status i.glyphicon-time {
      display: inline-block;
    }
    &.request-confirm .status i.glyphicon-ok {
      color: green;
    }
    &.request-reject .status i.glyphicon-remove {
      color: #ce4d4d;
    }
    &.request-confirm {
      background: #f3fff3;
    }
    &.request-reject {
      background: #ffeded;
    }
  }
}

.list-roles {
  md-menu-item i {
    display: none;
  }
  &.current-1 md-menu-item.role-1 i,
  &.current-2 md-menu-item.role-2 i,
  &.current-3 md-menu-item.role-3 i {
    display: inline-block;
  }
}