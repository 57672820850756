[datepicker-popup-wrap] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: #3c3c3c 5px 5px 20px !important;
  border: none !important;
  outline: none !important;
  min-width: 260px !important;

  thead {
    background-color: #3faa98;

    button,
    th {
      background-color: #3faa98 !important;
      border-color: #3faa98 !important;
      border-radius: 0;
      outline: none !important;

      strong {
        text-transform: capitalize;
      }

      &[role="heading"] {
        width: 100%;
        font-size: 14px;
        text-transform: initial;
      }
    }

    tr:nth-child(2) {
      display: none;
    }
  }

  tbody {

    td {

      button {
        border-radius: 0 !important;
        border-left: 1px solid #434b53;
        border-bottom: 1px solid #434b53;

        &.active {
          background-color: #2c343c !important;
          border-color: #2c343c;
          outline: none;

          span {
            color: #fff;
          }
        }

        &.btn-info {
          background-color: #c15656 !important;
          border-color: #c15656;
          outline: none;

          span {
            color: #fff;
          }
        }

        &[disabled="disabled"] {
          background-color: #cccccc !important;
          border-color: #cfcfcf !important;
        }
      }
    }
  }

  [ng-if="showButtonBar"] {
    background-color: #3faa98;
    overflow: auto;

    .btn-success {
      border-radius: 0;
      background-color: #c15656;
      border: 1px solid #434b53 !important;
    }

    .btn-group {
      padding: 0 0 5px;

      .btn {
        border-radius: 0;
        background-color: #2c353d;
        border: 1px solid #434b53 !important;
      }
    }
  }
}

// kludge for same consistency of dropdowns
.input-group {

  table.uib-monthpicker,
  table.uib-daypicker {
    width: 100%;
  }

  .dropdown-menu {
    min-width: 300px !important;
  }
}

.uib-datepicker {
    .btn {
        // min-width: 48px !important;
        margin: 0 !important;
        border-radius: 0 !important;
    }
}
.uib-button-bar {
    .btn {
        min-width: 48px !important;
        margin-left: 0 !important;
    }
}
// .datepicker,
// .table-condensed {
//   width: 300px;
//   height: 300px;
// }