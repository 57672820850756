$header-height: 40px;
$content-lg-width: 1200px;
$content-md-width: 960px;
$content-sm-width: 768px;
$content-xs-width: 599px;
$left-side-bar-width: 220px;
$left-side-bar-toggle-btn-width: 40px;

@import "../../node_modules/compass-sass-mixins/lib/animation/_core.sass";
@import "../../node_modules/angular-material/angular-material.scss";
@import "../icons";
@import "../flash-alert";
@import "../override/variables-provider";
@import "../override/datepicker";
@import "../bootstrap";
@import "../layout";
@import "../loading-bar";
@import "../tenders-list";
@import "../classifier";
@import "../delete-tender.modal";
@import "main";
@import "sidebar";
@import "sidebar-profile";
@import "tender-view";
@import "bid-view";
@import "planning-overview";
@import "profile";
@import "requests";
@import "info-popover";
@import "company";
@import "subscription";
@import "notifications";
@import "../override/v-accordion";
@import "../new-app";
@import "../create-tender";
@import "../classifier";
@import "../quality";
@import "../lot";
@import "../planning/planning-create";
@import "../planning/planning-overview";
@import "../override/material";
@import "../loader";
// @import "../jsondiffpatch_html";
@import "../md-dialog";
@import "../agree-modal";
@import "../agreement-page";
@import "print";
@import "test-mode";
@import "../FullCalendar";
@import "calendar_page";
a {
    &[ui-sref-active="active"] {
        &.active {
            color: $active-state-color;
        }
    }
}
:focus {
    outline: none !important;
}

div[ui-view],
ui-view {
    width: 100% !important;
    display: block;
}


@include keyframes(spin) {
    100% {
        stroke-dashoffset: 3130px;
    }

    0% {
        stroke-dashoffset: -3130px;
    }
}

.circle {
    @include animation(spin 15s linear 0s infinite alternate);
}

.circle2 {
    @include animation(spin 15s linear .5s infinite alternate);
}

.spinner-wrapper {
    @include display-flex();
    @include align-items(center);
    @include flex-direction(column);
    @include justify-content(center);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .spinner {
        height: 50px;
        width: 50px
    }
}

.more-menu {
    >i {
        margin-top: 15px;
        cursor: pointer;
        border: 2px solid #a5a5a5;
        color: #a5a5a5;
        border-radius: 50%;
        padding: 4px 5px;
        font-size: 12px;
    }

    >i:hover {
        border: 2px solid #000;
        color: #000;
    }

    >.dropdown-menu>li {
        cursor: pointer;

        a {
            padding: 7px 20px;

            i {
                margin-right: 6px;
            }
        }
    }
}

.md-open-menu-container {
    z-index: 1000;
}

.md-button.md-raised {
    white-space: normal;
    padding: 10px 7px;
    line-height: 23px;
    border-radius: 0;
}

.status-document {
    i {
        display: none;
    }

    &.ds-waiting i.glyphicon-time,
    &.ds-error i.glyphicon-remove,
    &.ds-uploading i.glyphicon-refresh,
    &.ds-uploaded i.glyphicon-ok {
        display: inline-block;
    }

    &.ds-waiting i.glyphicon-time {
        color: #d2d2d2;
    }

    &.ds-error {
        color: #c12929;
    }

    &.ds-uploaded {
        color: #3a7d2b;
    }

    &.ds-uploading i.glyphicon-refresh {
        display: inline-block;
        animation-name: rotation-status-icon;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

md-dialog {
    border-radius: 0px;
}

.content__title {
    margin: 0 0 10px 0;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    margin: -8px -8px 0px -8px;
}

footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 80;
}

.user-wallet {
    background: #f7fffc;
    z-index: 11;
    padding: 12px 8px;
    border-left: 5px solid #1e8e66;
    font-size: 15px;
    width: 320px;
    text-align: center;
    position: relative;

    @media (min-width: $content-sm-width) {
        width: 360px;
    }

    span {
        font-weight: bold;
        font-size: 17px;
    }

    a {
        color: #258071;
        font-weight: bold;
        float: right;
        position: relative;
        top: 3px;
    }

    .user-wallet__overdraft {
        font-size: 13px;
        margin-top: 4px;
        padding-top: 4px;
        border-top: 1px solid #d1eae1;
    }

    &.low-ballance {
        background: #f5e3e3;

        span {
            color: #c12929;
        }

        .user-wallet__overdraft {
            border-top: 1px solid #ead1d1;
        }
    }
}

.low-balance-warning {
    width: 320px;
    z-index: 11;
    padding: 1em;
    word-wrap: break-word;
    background: #ab1616;
    color: #fff;

    @media (min-width: $content-sm-width) {
        width: 360px;
    }
}

md-menu-item .glyphicon {
    margin-left: 3px;
    margin-right: 8px;
    top: 3px;
}

info-message {
    display: block;

    .info-message {
        background: #ca1515;
        color: white;
        text-align: center;
        padding: 12px;
        font-size: 15px;

        i {
            position: relative;
            top: 5px;
            cursor: pointer;

            &:hover {
                color: #cbf9e9;
            }
        }
    }
}

.page-form {

    //padding-top: 20px;
    .content-form,
    md-content {
        margin-left: 57px;

        @media (max-width: $content-md-width) {
            margin-left: 0px;
        }
    }
}

form .error-message {
    font-size: 12px;
    color: #c10c0c;
    margin: -2px 0 5px 5px;
}

.setMaxWidthLarge {
    max-width: 602px;
}

.setMaxWidthSmall {
    max-width: 375px;
}

select option:disabled {
  color: #cccccc !important;
}

.vAccordion--default v-pane-header::after,
.vAccordion--default v-pane-header::before {
    width: 20px;
    height: 4px;
    top: 60%;
    background-color: #3FAA98;
}

.vAccordion--default v-pane.is-expanded>v-pane-header {
    border-bottom-color: #3FAA98;
}

v-pane-header .tender-block__title {
    min-height: 30px;
    margin-right: 25px;
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}

.infobox-light-color {
    color: #666666;
    border: 1px solid #ccc;
    background-color: #fffeee;
    margin-bottom: 20px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.px-5 {
    padding-left: 5px;
    padding-right: 5px;
}
// customization of IIT EDS
#signPlaceholder .container {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    #keyStatusPanel {
        .col-sm-3, .col-sm-1 {
            width: 100%;
        }
        .col-sm-2, .col-sm-6, .col-sm-7 {
            width: 50%;
        }
        .form-control {
            height: 40px;
        }
    }
}

#signPlaceholder #eu-sign-form {
    padding: 20px;
    div {
        margin-bottom: 20px;
    }
}
