$accent-color: #5bc0de;
$advanced-search-color: #edf7f5;

// * { outline: dotted 1px #aaa;}
.tenders-list,
.items-list {
    width: 100%;
    font-size: 16px;
    &.transactions {
        padding-top: 20px;
        a {
            &.disabled {
                pointer-events: none;
                cursor: default;
            }
        }
    }
    .badge {
        margin-bottom: 5px;
        margin-right: 5px;
    }
    .navigate-panel {
        margin: 0;
        @include display-flex;
        @include align-items(center);
        @include justify-content(space-between);
        min-height: 70px;

        &.search-off {
            @include justify-content(flex-end);
        }

        .search-field {
            position: relative;
            width: 300px;
            border-radius: 2px;
            background-color: #FAFAFA;
            transition: width 0.2s ease-out;

            .search {
                position: absolute;
                top: 13px;
                right: 10px;
                cursor: pointer;
                color: #bdbdbd;
            }

            input {
                background-color: transparent;
                padding-right: 35px;
                border: none;
                height: 40px;

                &:focus {
                    &::-webkit-input-placeholder {
                        color: #e0e0e0;
                    }

                    &::-moz-placeholder {
                        color: #e0e0e0;
                    }

                    &:-moz-placeholder {
                        color: #e0e0e0;
                    }

                    &:-ms-input-placeholder {
                        color: #e0e0e0;
                    }
                }
            }

            .buttons-wrapper {
                position: absolute;
                left: 304px;
                top: 3px;
                width: 340px;
                transition: left 0.2s ease-out;
                z-index: 90;

                @media (max-width: $content-md-width) {
                    width: auto;
                    left: auto;
                    right: 0;
                }

                .btn {
                    border-top-right-radius: 2px !important;
                    border-bottom-right-radius: 2px !important;
                    margin-right: 4px;
                    padding: 6px 8px;
                }

                .btn-report {
                    border: solid 1px #3faa98;
                    background-color: #3faa98;
                }

                .plus-btn {
                    margin-left: 10px;
                    border-radius: 2px;
                    background-color: #3faa98;

                    .plus {
                        display: inline-block;
                        transform: scale(0.6);
                        position: relative;
                        bottom: 2px;
                    }
                }
            }
        }

        .pagination-wrapper {
            height: 70px;
        }

        .glyphicon-remove {
            top: 2px;
        }

        &.search-focused {
            .search-field {
                width: 480px;

                @media (max-width: $content-md-width) {
                    width: 100%;
                }

                .buttons-wrapper {
                    left: 484px;

                    @media (max-width: $content-md-width) {
                        left: auto;
                    }
                }
            }

            .pagination-wrapper {
                display: none;
            }
        }
    }

    .search {
        margin: 0 auto 30px;

        &.shadow {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
        }

        /* override global margin */
        md-input-container {
            margin: 8px 0 !important;
        }

        md-input-container.md-input-has-placeholder {
            margin: 8px 0 !important;
        }
        .md-primary {
            color: $accent-color;
        }
        .search-query-container {
            margin-top: 0;
            margin-bottom: 0;
        }

        .search-query-button {
            margin-top: 0;
            margin-bottom: 0;
            padding: 0 15px;
            min-width: 80px;
            &.md-primary {
                color: #fff;
            }
        }

        .query-clear {
            margin: 6px -40px 6px 0;
            min-width: 40px;
        }

        .md-errors-spacer {
            display: none;
        }

        .full-width {
            width: 100%;
        }

        md-block.md-small {
            padding: 0 8px !important;
            margin: 0;
        }

        .md-button.md-small {
            padding: 0 8px;
            margin: 0;
        }

        h5 {
            margin: 0 auto;
        }

        md-datepicker {
            display: block;
        }

        .no-v-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .paging {
            height: 70px;
        }

        .save-params {
           background-color: $advanced-search-color - #050505;
        }

        .search-advanced-panel {
            background: $advanced-search-color;
        }

        .search-advanced {
            background: $advanced-search-color;
        }

        .search-params-indicators {
            width: 100%;
            font-size: 13px;
            margin-top: 8px;

            .indicator_title {
                display: inline-block;
            }

            .indicator {
                display: inline-block;
                background-color: $advanced-search-color - #050505;
                border-radius: 2px;
                padding: 0px 5px;
                margin: 1px 16px 1px 0;
                text-align: center;
                color: #757575;
                white-space: nowrap;
                position: relative;

                &:hover {
                    background-color: $advanced-search-color - #101010;

                    span {
                        color: #616161;
                        cursor: none;
                    }

                    .dropdown-menu {
                        display: block;
                        color: #616161;
                        max-width: 400px;
                        min-width: 300px;
                        padding: 10px;
                        white-space: normal;
                        font-size: 13px;

                        li {
                            margin-bottom: 8px;
                            line-height: 1;
                            padding-left: 18px;

                            &:before {
                                content: "✓";
                                margin-left: -16px;
                                display: inline-block;
                                width: 16px;
                                color: $accent-color;
                            }
                        }
                    }
                }
            }

            .indicator-active {
                color: #fff;
                background-color: $accent-color;
            }
        }

        .md-button {
            md-icon {
                position: absolute;
                top: 8px;
            }

            &.md-icon-center {
                md-icon {
                    left: 50%;
                    margin-left: -12px;
                }
            }

            &.md-icon-left {
                padding-left: 32px;

                md-icon {
                    left: 2px;
                }
            }

            &.md-icon-right {
                padding-right: 32px;

                md-icon {
                    right: 2px;
                }
            }
        }

        md-icon {
            font-family: 'Glyphicons Halflings';

            &:before {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
                font-size: 20px;
            }
        }
    }

    a.report {
        cursor: pointer;
        color: $accent-color;
        position: relative;
        display: inline-block;
        max-width: 200px;
        margin: 0;
        padding: 3px 0 3px 25px;
        white-space: nowrap;

        &:hover {
            text-decoration: none;
        }

        @media (max-width: 767px) {
            margin: 12px 20px;
        }
    }

    label {
        font-weight: normal;
    }

    .list {

        .row {
            font-size: 12px;
            padding: 16px 0px 16px 16px;
            // cursor: pointer;
            border-top: 1px solid #dbdbdb;

            &.tender-info,
            &.item-info {
                display: block;
                text-decoration: none;
                margin-right: 0;
                margin-top: 0;
            }

            &.bill {
                cursor: default;
            }
        }


        .item-row {
            &.status-row {
                margin-top: 6px;
                margin-bottom: 13px;
            }

            &.cpv-row {
                margin-bottom: 9px;
            }

            &.forward-link-row {
                a {
                    text-decoration: none;
                    color: $text-cyan; 
                    font-weight: 500;
                    font-size: 14px;
                }
                a:hover {
                    color: $text-dark-gray;
                }
            }

            width: 100%;
            @include display-flex();

            .caption {
                display: inline-block;
                color: $text-light-gray;
            }

            .left {

                //@include flex(0 0 70%);
                //padding-right: 10px;
                .caption {
                    width: 90px;
                }

                .cpv,
                .dkpp {
                    color: $text-color;
                    line-height: 18px;
                }

                .list-icon-amount {
                    color: #888888;
                    margin-right: 15px;
                }
            }

            .right {

                //@include flex(0 0 30%);
                .caption {
                    width: 130px;
                }

                .bids,
                .enquiry {
                    color: $text-dark-gray;
                }

                .span-row {
                    margin-top: 9px;
                }
            }
        }
    }

    .title {
        font-weight: 500;
        font-size: 18px;
        color: $text-cyan;
        word-break: break-all;
        padding-right: 10px;
        text-decoration: none;

        &:first-letter {
            text-transform: capitalize;
        }
    }

    .title:hover {
        color: $text-dark-gray;
    }

    .id {
        color: $text-vinous;
        margin-right: 10px;
    }

    .company {
        text-decoration: underline;
        color: #000;
    }

    .status {
        color: $text-carrot;
        width: 200px;
        // display: inline-block;
        border: 1px solid $text-carrot;
        border-radius: 4px;
        padding: 5px;
        text-align: center;

        @media (max-width: $content-md-width) {
            margin-top: 10px;
        }
    }

    .amount {
        font-weight: 500;
        font-size: 18px;
        color: $text-dark-gray;
        padding-left: 10px;
        border-left: 4px solid #3faa98;
        margin-bottom: 10px;

        .tariff {
            font-size: 12px;
            font-weight: normal;
            color: $text-light-gray;

            &__amount {
                color: $text-dark-gray;
                font-weight: 500;
            }
        }
    }

    .accent {
        color: $accent-color;
    }
}

.md-trim-select {
    max-width: 420px;
    overflow: hidden;
    padding-top: 50px;
    @media (max-width: $content-xs-width) {
        max-width: 300px;
    }
    md-optgroup {
        padding-top: 50px;
    }
    md-select-header {
        position: fixed;
        width: 100%;
        border-bottom: solid 1px #d6d6d6;
        background: #fff;
        z-index: 100;
    }
}

.select-header {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
    padding-left: 10.667px;
    height: 48px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    .header-searchbox {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        padding: 0;
    }
}

.bookmark{
    margin-right: 3px;
    display: inline-block;
    .glyphicon{
        transition: 0.3s color linear;
        font-size: 18px;
    }
    &.dark .glyphicon{
        color: #008c78;
    }
}
.participant {
    margin-right: 3px;
    display: inline-block;
    background-color: #edf7f5;
    padding: 3px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: #333333;
    transition: all ease-out 0.3s;
    &.dark {
        color: #ffffff;
        background-color: #008c78;
    }
}
.bookmark:hover .glyphicon{
    color: #5bc0de;
}

.participant {
    background-color: antiquewhite;
    margin-left: 20px;;
}
.participant:hover {
    color: #ffffff;
    background-color: indianred;
}

.select_input_field {
    border: 1px solid #ccc;
    margin: 8px;
    width: inherit;
    padding: 4px;
}
.time-holder input {
    height: auto;
    width: 42px;
    font-size: 10px;
    align-self: center;
}