@import "create-tender.attachDocuments.modal";
@import "delete-tender.modal";
.create-tender-wrapper {
    width: 600px;
    @media (min-width: $content-lg-width) {
        width: 720px;
    }
    h3 {
        padding-bottom: 0.3em;
        border-bottom: solid 2px #3eaa97;
        margin-bottom: 20px;
    }
    hr {
        border-top: 1px solid #ddd;
    }
    .items-wrapper {
        position: relative;
        .short-description {
            hr {
                border-top: 2px solid #ddd;
            }

        }
        .remove-field {
            position: absolute;
            right: -25px;
            top: 60px;
            transition: transform 1s ease 0.5s;
            &:hover {
                transform: rotate(360deg);
            }
        }
        .add-field {
            position: absolute;
            right: -25px;
            top: 20px;
            transition: transform 1s ease 0.5s;
            &:hover {
                transform: rotate(-360deg);
            }
        }
    }
    hr.size-2 {
        border-top: 2px solid #ddd;
    }
    .form-control[readonly] {
        cursor: pointer;
    }
    div.left {
        float: left;
        display: flex;
        align-items: center;
    }
    div.right {
        float: right;
    }
    input.num,
    select.num {
        width: 110px;
    }
    select.sel {
        width: 110px;
    }
    a {
        cursor: pointer;
        color: #000;
        text-decoration: none;
    }
    .row {
        margin: 0;
        &.header {
            height: 40px;
            background-color: #3FAA98;
            color: white;
            padding: 0px 30px;
            z-index: 80;
        }
        &.top-fields {
            margin: 10px 0 0;
        }
        &.middle-fields input {
            border-top: none;
        }
        &.buttons {
            margin: 20px -15px;
        }
        .classifier-input {
            width: 100%;
            padding-right: 16px;
        }
        .classifier-field {
            display: inline-block;
            input {
                padding-right: 50px;
            }
        }
    }
    label {
        padding-top: 10px;
        cursor: pointer;
        font-weight: normal;
        padding-right: 8px;
        &.left {
            width: 276px;
        }
        &.left-wide {
            width: 276px;
        }
        &[for="with-nds"] {
            margin-left: 5px;
        }
        &[for="step-percent"] {
            margin-right: 10px;
        }
        &[for="currency"] {
            margin-right: 10px;
        }
    }
    .unit-dropdown {
        margin-left: 18px;
        li {
            text-transform: lowercase;
        }
    }
    .input-icon {
        position: absolute;
        right: 30px;
        top: 25px;
    }
    .checkbox {
        margin-left: 18px;
    }
    [type='checkbox'] {
        height: 20px;
        width: 20px;
    }
    .form-inline .input-group>.form-control.date {
        width: 170px;
    }
    input.date {
        width: 170px;
    }
    .calendar-btn {
        z-index: 10;
        transform: scale(0.7);
    }
    .timepicker {
        height: $input-height-base;
        margin-left: 6px;
        table {
            width: 115px;
        } // hide arrows
        tbody tr:first-child,
        tbody tr:last-child {
            display: none;
        }
        td {
            background-color: $input-bg;
        }
        .form-control {
            width: 55px;
            border-top: none;
            border-left: none;
            border-right: none;
        }
    }
    .tender-creating-info ul {
        width: 500px;
        margin-left: -250px;
        position: relative;
        left: 50%;
        top: 0;
        padding: 0;
        position: fixed;
        z-index: 99999999;
        background: white;
        padding: 10px;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 0 4px #fff;
        li {
            border-bottom: 1px solid #e4e4e4;
            padding: 5px;
            list-style: none;
        }
    }
    .tender-creating-info::after {
        background: black;
        opacity: 0.5;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        display: block;
        content: ' ';
        z-index: 9999999;
    }
    @keyframes rotation-status-icon {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .criterias {
        margin: 30px 0;
        .title {
            padding-left: 10px;
            margin-left: 12px;
            line-height: 25px;
        }
        md-checkbox {
            margin-bottom: 2px;
        }
        .marker-activate {
            font-size: 22px;
            color: #3eaa97;
        }
        .description {
            color: #555555;
            font-size: 12px;
            margin: 15px 0px;
        }
        .legislation li a {
            color: #555555;
        }
        .legislation li a:hover {
            color: #cccccc;
            border-bottom: 1px solid #cccccc;
        }
        .active-block {
            //border: 2px solid #3eaa97;
            padding: 20px 0;
            .requirements .requirements-title {
                padding-left: 10px;
                margin-top: 20px;
                margin-left: 10px;
                border-left: 2px solid #3eaa9750;
                &.cancelled {
                    opacity: 0.7;
                }
            }
            .requirements .requirements-fields {
                margin-bottom: 30px;
                form {
                    padding-left: 10px;
                    margin-top: 20px;
                    margin-left: 10px;

                    &.ng-dirty {
                        padding-left: 8px;
                        border-left: 2px solid #3eaa9750;
                        .icon-save {
                            box-shadow: 0px -1px 5px 3px #5ab9ad;
                            border-radius: 4px;
                        }
                    }
                }
                textarea {
                    min-height: 100px;
                }
                hr {
                    border-top: 1px solid #cccccc;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    }
            }

            hr {
                border-top: 2px solid #3faa98;
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }
}

.required-link {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiAQEQDx3qPI2aAAAAwUlEQVQoz5WRsQqCUBSGPyx9ggbXiBAXp7Z2X8QewM1XUIjwBZI2B5/A6U6CQ0sQiISv0aAgDbdAb0l1lsM957v/Of+9MA4Pb1yYK8BaOaPxJTTAnOyaEkgp2Lw1NxSkEnDJyEkGSiYJORmuBDpiLFoqAgwMAipaLGK6saiDoKdH4PzhYvbMCw7sOXGl5MKRJSX3l4KOT42BTURLS4SNQY2PLm+Lgc2QcGBTyKfe0XwYfmbLSo5oJjdsfnCh/uZNBR6UWStKEWtd4gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0wMS0wMVQxNjoxNToyOSswMTowMM/8Z+IAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMDEtMDFUMTY6MTU6MjkrMDE6MDC+od9eAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==');
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: calc(100% - 3px) 3px;
}

.background-dropdown{
    background-position: calc(100% - 3px) 10px;
}
.requirement-status-active {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1px 4px;
    margin-right: 5px;
    background-color: bisque;
    &.cancelled {
        background-color: #f3e9dd
    }
}