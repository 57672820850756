@import "../tender-view/tender-view";
@import "tender-view.documents";
@import "tender-view.chat";
@import "tender-view.bid.modal";
.tender-view-wrapper {
    .header {
        .right {
            padding-right: 30px;
        }
    }
}

.procurement-info {
    margin-top: 5px;
}

.procurement-type,
.procurement-sign {
    font-size: 14px;
    margin-bottom: 5px;
}

.make-bid {
    background: #3faa98;
    text-align: center;
    padding: 8px 12px;
    cursor: pointer;
    margin: 10px 0;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
}

.scroll-page {
    width: 100px;
    bottom: 80px;
    right: 0;
    background: white;
    position: fixed;
    z-index: 999;
    opacity: 0;
    transition: opacity 1s;
    @media (max-width: $content-md-width) {
        margin-left: 0;
        top: 100px;
        bottom: auto;
        right: 25px;
        width: auto;
        max-width: 200px;
    }
    &__bid {
        background-color: #3faa98;
        color: #fff;
        padding: 13px;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        cursor: pointer;
    }
    &__top i {
        display: inline-block !important;
        margin-left: 3px;
        background-color: #ccc;
        padding: 10px;
        font-size: 30px;
        margin-top: -1px;
        color: #fff;
        cursor: pointer;
    }
}

.modal-open .scroll-page {
    display: none;
}

// .tender-wrapper {
//     padding-top: 80px;
//     @media (max-width: $content-md-width) {
//         padding-top: 40px;
//     }
// }

.tender-sidebar {
    min-width: 200px;
    max-width: 200px;
    .bid-placed {
        margin: 1em auto;
    }
    @media (max-width: $content-md-width) {
        min-width: 100%;
        max-width: 100%;
        .bid-placed {
            margin-bottom: 10px;
        }
    }
}

.tender-documents {
    margin: 8px 0;
}

.tender-steps {
    margin: 0;
    padding: 0;
    list-style: none;
}

.tender-steps_item {
    padding: 8px 12px;
    margin: 0;
    border-left: 4px solid #a4d5c5;
    div {
        font-size: 13px;
    }

    &--end {
        display: none;
    }
    &--finished &--end,
    &--current &--end {
        display: block;
    }
    &--finished &--start,
    &--current &--start {
        display: none;
    }
    &--without-end &--end {
        display: none;
    }
    &--without-end &--start {
        display: block;
    }
    &--current {
        border-color: #3faa98;
        div,
        a {
            font-weight: 700;
            color: #3FAA98;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &--light_blue {
        border-color: #5BC0DE;
        div {
            color: #5BC0DE;
        }
    }
    &--orange {
        border-color: #F3AD06;
        div {
            color: #F3AD06;

        }
    }
    &--green {
        border-color: #3FAA98;
        div {
            color: #3FAA98;

        }
    }
    &--blue {
        border-color: #467FAB;
        div {
            color: #467FAB;

        }
    }
    &--finished {
        border-color: #f2f2f2;
        div {
            color: #CCCCCC;
        }
    }
    &--finished .period-end {
        display: none;
    }
    &--days-until-the-end {
        font-size: 12px;
        .period-end {
            color: #606060;
            font-size: 12px;
            font-weight: normal;
        }
        .period-end-today {
            color: #c15656;
            font-size: 12px;
        }
    }
    &--border {
        border: 4px solid #3faa98;
        .period-end {
            color: #c15656;
            font-weight: 600;
        }
    }

}

.documents-list {
    margin: 20px 0 0 0;
    padding: 0;
    list-style: none;
    .document__item {
        margin: 10px 0;
        padding-left: 28px;
        background-image: url('/static/images/icon/text.png');
        background-position: top left;
        background-repeat: no-repeat;
        min-height: 35px;
        &--img-file {
            background-image: url('/static/images/icon/image.png');
        }
        &--doc-file {
            background-image: url('/static/images/icon/word.png');
        }
        &:hover .document__type,
        &:hover .document__date {
            display: block;
        }
    }
    .document__title a {
        font-size: 14px;
        text-decoration: underline;
        color: #3FAA98;
        word-wrap: break-word;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
    .document__type,
    .document__date {
        display: none;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
    }
    .document__show__type,
    .document__show__date {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
    }
}

.bid-item {
    &__title {
        padding: 7px;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        background: #5ab9ad;
        color: #ffffff;
    }
    &__amount {
        padding: 10px 10px 0px 10px;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
    }
    &__date {
        text-align: center;
        display: block;
        font-size: 12px;
        color: #828282;
    }
    &__actions {
        padding: 7px;
        .md-button {
            margin: 0;
            // padding: 0 5px !important;
            font-size: 11px;
        }
        [layout="row"] {
            text-align: center;
        }
    }
    &__sign-info .sign-info {
        text-align: center;
        padding: 4px 0;
        font-size: 11px;
    }
    &__alert {
        margin-bottom: 10px;
        animation: pulse; // rotate-alert
        animation-duration: 2s;
        animation-iteration-count: infinite;
        padding: 10px;
        font-size: 15px;
        text-align: center;
        position: relative;
    }
    &__big {
        font-size: 16px;
        font-weight: 600;
    }
}

@keyframes pulse {
    0% {
        background: rgb(198, 40, 40);
    }
    30% {
        background: darken(rgb(198, 40, 40), 10%);
    }
    60% {
        background: rgb(141, 23, 23);
    }
}
// @keyframes rotate-alert {
//     0% {
//         transform: rotateZ(0deg);
//     }
//     20% {
//         transform: rotateZ(5deg);
//     }
//     80% {
//         transform: rotateZ(-5deg);
//     }
//     100% {
//         transform: rotateZ(0deg);
//     }
// }

.sign-info {
    font-weight: bold;
    font-size: 14px;
    &__date {
        color: #868686;
    }
}

.tender-content {
    padding-right: 30px;
    padding-bottom: 100px;
    @media (max-width: $content-md-width) {
        padding-right: 0px;
    }
}
