.bid-content {
    h1 {
        font-size: 24px;
        color: #333333;
        margin: 0 0 20px 0;
    }
    .wiki {
        a {
            font-size: 30px;
            color: #008c78;
            display: flex;
            align-items: center;
            text-decoration: none;
            transition: color 0.3s ease-out 0s;
            span, i {
                display: inline-block;
                margin-left: 5px;
            }
            .help-text {
                font-size: 14px;
                font-weight: 500;
            }
        }
        a:hover {
            color: #5ab9ad;
        }
    }
    .notice {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
        i {
            font-size: 30px;
            color: #008c78;
            margin-left: 20px;
        }
    }
    .notice-publish-status {
        @extend .notice;
        justify-content: flex-start;
        border: 1px solid #008c78;
        padding: 20px;
        background-color: antiquewhite;
        i {
            margin-left: 0;
            margin-right: 20px;
        }
    }
    .notice-publish-status-danger {
        border: 1px solid red;
        i {
            color: red;
        }
    }
    .outer-box {
        &__title {
            color: #666666;
            font-size: 14px;
            margin-bottom: 5px;
        }
        &__sort {
            margin-top: 10px;
            margin-bottom: 5px;
            text-decoration: underline;
            padding: 2px 15px;
            border-radius: 12px;
            cursor: pointer;
        }
        &__sort:hover {
            color: #ffffff;
            background-color: #3faa98;
        }
        &__body {
            padding: 20px;
            border: 1px solid #ccc;
            margin-bottom: 20px;
            .body-btns {
                display: flex;
                justify-content: flex-end;
                .btn {
                    color: #333333;
                    font-size: 12px;
                    margin-left: 10px;
                    padding: 2px 15px;
                    border-radius: 12px;
                    border-color: #1d6e61;
                    background-color: #ffffff;
                    transition: box-shadow .4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color .4s cubic-bezier(0.25, 0.8, 0.25, 1);
                    i {
                        color: #1d6e61;
                    }
                }
                .btn:hover,
                .active {
                    color: #ffffff;
                    background-color: #3faa98;

                    i {
                        color: #ffffff;
                    }
                }
                .active:hover {
                    background-color: #248877;
                }
                .active {
                    background-color: #ec9898;
                    border: 2px solid #f56766;
                }
            }
            .body-row {
                margin-bottom: 10px;
                .label {
                    color: #666666;
                    font-size: 12px;
                    font-weight: normal;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    white-space: normal;
                    text-align: left;
                    display: inline-block;
                }
                .data {
                    min-height: 36px;
                    background-color: #eeeeee50;
                    border: 1px solid #c5c5c5;
                    padding: 6px;
                    .bold {
                        color: #3a3a3a;
                        font-weight: 500;
                    }
                    .light {
                        color: #818181;
                    }
                    .big {
                        font-size: 22px;
                    }
                    .evidences {
                        margin-left: 10px;
                        margin-right: 10px;
                        border-left: 5px solid #3eaa9770;
                        padding-left: 10px;
                    }
                    .evidences-description {
                        background-color: #ffffff;
                        min-height: 100px;
                    }
                    .requirement-title-label {
                        margin: 10px 10px 5px;
                        color: #3faa98;
                        font-weight: 600;
                    }
                    .requirement-title-block {
                        border: 3px solid #3faa98;
                        padding: 10px;
                        margin: 0 10px 10px;
                    }
                    .requirement-title-block-short {
                        margin: 0 10px 10px;
                    }
                    .eligibleEvidence-title-label {
                        color: #3faa98;
                        border-bottom: 2px dotted #3faa98;
                        margin-bottom: 10px;
                    }
                }
                i.criteria-marker {
                    font-size: 30px;
                    color: #008c78;
                    margin-top: 3px;
                }
            }
            .body-document-row {
                position: relative;
                margin-bottom: 10px;
                padding: 10px;
                border-bottom: 1px solid #cccccc;
                &.last{
                    border-bottom: none;
                    margin-bottom: 0;
                    margin-top: -10px;
                }
                .deleted-row {
                    opacity: 0.5;
                }
                .error-row {
                    background-color: rgb(255, 180, 180);
                }
                .current_version {
                    display: flex;
                    justify-content: start;
                    margin-bottom: 5px;
                    .document-name{
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        .name {
                            flex-grow: 0;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .extension{
                            flex: 1 0 auto;
                        }
                    }
                    .doc-icon {
                        font-size: 22px;
                        margin-right: 8px;
                    }
                    .info-button {
                        font-size: 12px;
                        color: #3faa98;
                        padding: 0 10px;
                        border-left: 3px solid #3faa98;
                        margin-left: 10px;
                        text-decoration: underline;
                    }
                    .info-button:hover {
                        color: #1d6e61;
                    }
                }
                .previous_versions {
                    margin-left: 30px;
                    padding-left: 10px;
                    border-left: 3px solid #dddddd;
                    a {
                        color: #999999;
                        margin-bottom: 5px;
                        display: inline-block;
                    }
                    a:hover {
                        color: #5a5a5a;
                    }
                }
                a {
                    color: #3a3a3a;
                    text-decoration: none;
                    .document-name{
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        .name {
                            flex-grow: 0;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .extension{
                            flex: 1 0 auto;
                        }
                    }
                }
                a:hover {
                    color: #202020;
                }
                .grey {
                    color: #cccccc;
                }
                .date {
                    font-size: 11px;
                    color: #999999;
                }
                md-select {
                    margin: 0;
                    md-select-value {
                        border: none;
                    }
                }
                md-select.documentType md-select-value div {
                    white-space: normal;
                }
                .upper-text {
                    //position: absolute;
                    width: 100%;
                    padding: 6px 30px 6px 6px;
                    top: 24px;
                    right: -21px;
                    border: 1px solid #cccccc;
                    //box-shadow: 0px 1px 5px #cccccc;
                    z-index: 100;
                    &.ng-invalid.ng-touched{
                        border: 1px solid red;
                    }
                }
                .relatedItemTitle {
                    font-size: 12px;
                    cursor: pointer;
                }
                .delete-replace-block {
                    a {
                        color: #008c78;
                        font-size: 22px;
                        transition: color 0.3s ease-out 0s;
                    }
                    a:hover {
                        color: rgb(63,170,152);
                    }
                }
            }
            hr {
                margin-top: 30px;
                margin-bottom: 30px;
                border: 0;
                border-top: 1px solid #cccccc80;
            }
            .body-progress {
                padding: 20px;
                .progress {
                    border-radius: 5px;
                    .progress-bar-success {
                        background-color: rgb(63,170,152);
                    }
                }

            }
            .group-radio-button {
                margin-top: 14px;
                margin-left: 16px;
            }
        }
        .document-error{
            p, ul {
                color: rgb(198, 40, 40)
            }

        }
    }
}
.show-document-modal {
    border: 5px solid #ffffff;
    &__body {
        height: 75vh !important;
        background-color: #efefef;
    }
    .show-document-closer {
        display: block;
        position: absolute;
        right: -40px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        padding: 8px;
        top: -40px;
    }
    .meta-data {
        padding: 20px;
        h3 {
            margin-bottom: 40px;
        }
        .row {
            margin-bottom: 10px;
            .col-sm-4 {
                padding: 10px;
            }
            .col-sm-8 {
                background-color: #ffffff;
                padding: 10px;
            }
        }
    }
}
.show-sign-modal {
    border: 5px solid #ffffff;
    &__body {
        background-color: #ffffff;
        .bottom-closer {
            text-align: center;
            padding: 20px;
            margin-bottom: 0;
        }
        .spinner-outer {
            padding-top: 30px;
            padding-bottom: 30px;
            text-align: center;
        }
        #loading-bar-spinner {
            top: 40%;
            left: 48%;
        }
    }
    .show-sign-closer, .show-sign-refresh {
        display: block;
        position: absolute;
        right: -50px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        padding: 8px;
        top: -10px;
    }
    .show-sign-refresh {
        right: -50px;
        top: 35px;
    }
}
