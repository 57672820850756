@import "tender-view.bid.modal";

.auction-bid-documents {
  @media (max-width: $content-md-width) {
    margin-left: 0px;
  }
  .version {
    padding: 10px 0px;

    .title {
      text-decoration: line-through;
    }

    .details {
      color: $text-dark-gray;
    }
  }
}
.tender-view-bid-wrapper {
  .lot-title {
      color: #3faa98;
  }
  .u-control {
    font-weight: 700;
    font-size: 16px;
  }

  .form-control {
    padding: 10px 10px;
    height: auto;
    font-size: 18px;
    box-shadow: none;
    border: none;
  }

  .row {
    margin-bottom: 10px;
  }

  .title {
    font-size: 18px;

    a {
      color: #333333;
    }

    :hover {
      text-decoration: underline;
    }
  }

  .type {
    color: #333;
  }
}
