.alert-danger {
    font-size: 18px;
}

.fade {
    @include transition(0.15s);
}

button.close {
    padding-left: 10px;
    opacity: 1;
}

.alert-success {
    text-align: center;
    font-size: 15px;
    button.close {
        color: green;
    }
}

.server-validation-errors {
    margin: 0;
    padding: 5px 0 0 0;
    list-style: none;
    clear: left;
}

.server-validation-errors li {
    font-size: 12px;
    padding-top: 2px;
    line-height: 15px;
    color: #b93333;
    margin-top: 4px;
    padding-left: 4px;
    border-left: 1px solid #b93333;
}