.attachDocumentsModal {
  .modal-body {
    color: $text-dark-gray;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 46px 0;
  }

  .modal-footer {
    .btn {
      width: 50%;
      float: left;
    }

  }
}