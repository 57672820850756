.info-popover {
  width: 220px;
  position: fixed;
  top: 50px;
  right: 40px;
  z-index: 100;
  >md-content {
    background: #fff0f0;
  }
  &__content {
    text-align: center;
    a {
      color: #752727;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &:after {
    position: absolute;
    top: -9px;
    right: 40px;
    content: ' ';
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background: #fff0f0;
    box-shadow: -2px -2px 6px -3px #3c3c3c;
  }
}