.customer-modal-wrapper {
  .lot-wrapper {
    border-bottom: 1px solid #dddddd;
    @media (max-width: $content-md-width) {
      margin: 20px;
      padding: 0 0 10px 0;
    }
  }
  .row.dark-top-border {
    @media (max-width: $content-md-width) {
      margin: 20px;
      padding: 10px 0 0 0;
    }
  }

  .contenteditable {
    font-size: 22px;
    font-weight: bold;
    float: none;
    &::-webkit-input-placeholder {
      color:#cccccc;
      font-weight: 400;
    }
    &::-moz-placeholder {
      color:#cccccc;
      font-weight: 400;
    }
    &:-moz-placeholder {
      color:#cccccc;
      font-weight: 400;
    }
    &:-ms-input-placeholder {
      color:#cccccc;
      font-weight: 400;
    }

  }

  .compact {
      height: 30px;
  }

  .close-cross-black, .close-cross {
    cursor: pointer;
  }

  .cancel-modal-body {
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center;
  }

  .lot-header {
    &__budget {
      font-size: 13px;
      color: #7b7b7b;
      font-weight: normal;
      &--tariff {
          padding-top: 5px;
          font-weight: normal;
          &--amount {
              font-weight: bold;
              color: $text-dark-gray;
          }
      }
    }
  }
}


.modal-dialog.modal-drag {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
}
.modal-header[modal-draggable] {
  cursor: move;
}