.tender-view-chat-wrapper {
    .question-btn {
        margin-bottom: 30px;
    }

    .question-container {
        background-color: #F4F4F4;
        padding: 20px;
        cursor: pointer;

        .icon-quest {
            background-color: #3FAA98;
            height: 50px;
            width: 50px;
            border-radius: 30px;

            .icon {
                position: relative;
                top: 7.5px;
                left: 15px;
            }
        }

        .user {
            color: #000;
        }

        .date {
            color: #B0B0B0;
        }

        .question-title {
            color: #60B6A8;
            font-weight: bold;
            font-style: italic;
        }

        .question-description {
            color: #000;
            font-weight: bold;
            font-style: italic;
        }

        .answer {
            //display: none;
            opacity: 0;
            background-color: #20272D;
            height: 50px;
            width: 50px;
            border-radius: 30px;

            .icon {
                position: relative;
                top: 7.5px;
                left: 15px;
            }
        }

        &:nth-child(even) {
            background-color: #fff;
        }

        &:hover {

            .answer {
                opacity: 1;
            }
        }
    }
}