.marquee {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    span {
        color: #212121;
        font-size: 18px;
        display: inline-block;
        padding-left: 100%;
        animation: marquee 30s infinite linear;
        -webkit-font-smoothing: antialiased;
    }
}

@-webkit-keyframes marquee {
    0% {
        -webkit-transform: translate(0, 0);
    }

    100% {
        -webkit-transform: translate(-100%, 0);
    }
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-100%, 0)
    }
}