.tender-delete-modal {
   .modal-amku {
       padding: 20px;
   }

  .documents-block {
    overflow: hidden;
    padding: 20px;

    .attach {
      cursor: pointer;
      margin-right: 10px;
      margin-top: 2px;
    }
  }

  .form-control {
    z-index: 0;
  }

  .calendar-btn {
    font-size: 10px;
  }

  .timepicker {
    height: $input-height-base;
    margin-left: 8px;

    table {
      width: 115px;
    }

    // hide arrows
    tbody tr:first-child, tbody tr:last-child {
      display: none;
    }

    td {
      background-color: $input-bg;
    }

    .form-control {
      width: 55px;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}
