.lot {
  background-color: #fff;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  width: 840px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  .container {
    width: 100%;
    padding: 20px 20px 40px 20px;
    position: absolute;
    top: 0;
    bottom: 60px;
    overflow-y: auto;

    .row {
      &.middle-fields input {
        border-top: none;
      }
    }

    .checkbox {
      margin-left: 0px;
    }

    h3 {
      margin-bottom: 30px;
    }

    .option {
      margin-top: 20px;
    }

    .no-padding {
      padding: 0px;
    }

    .spin {
      transition: transform 1s ease 0.5s;

      &:hover {
        transform: rotate(360deg);
      }

      &-reverse {
        transition: transform 1s ease 0.5s;

        &:hover {
          transform: rotate(-360deg);
        }
      }
    }

    .remove-field:before {
      margin-bottom: 20px;
    }
  }

  .btn-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;

    .cancel {
      width: 35%;
    }

    .choose {
      width: 65%;
    }
  }
}