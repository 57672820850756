@import "../node_modules/compass-sass-mixins/lib/compass/css3/_transition.sass";

[flash-alert] {
  position: fixed;
  top: 50px;
  margin-left: -165px;
  left: 50%;
  max-width: 100%;
  min-width: 300px;
  z-index: 10000;
}

.fade {
  @include transition(0.3s);
}