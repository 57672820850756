.icon-white {
    font-size: 10px;
}

.icon-white:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("/static/images/spriteWhite.svg");
    background-repeat: no-repeat;
    background-size: 26em 25em;
}

.no-svg .icon-white:before {
    background-image: url("/static/images/spriteWhite.png");
}

.icon-white.archive:before {
    background-position: 0em 0em;
    width: 1.8em;
    height: 1.6em;
}

.icon-white.auction:before {
    background-position: -1.8em -1.6em;
    width: 2em;
    height: 2em;
}

.icon-white.contract:before {
    background-position: -13.2em -12.5em;
    width: 2em;
    height: 2em;
}

.icon-white.planning:before {
    background-position: -3.8em -3.6em;
    width: 2em;
    height: 2em;
}


.icon-white.bage:before {
    background-position: -3.8em -3.6em;
    width: 2em;
    height: 1.6em;
}

.icon-white.close-cross:before {
    background-position: -5.8em -5.2em;
    width: 1.8em;
    height: 1.8em;
}

.icon-white.doc:before {
    background-position: -7.6em -7em;
    width: 1.6em;
    height: 2em;
}

.icon-white.exit:before {
    background-position: -9.2em -9em;
    width: 2em;
    height: 1.8em;
}

.icon-white.export:before {
    background-position: -11.2em -10.8em;
    width: 2em;
    height: 1.8em;
}

.icon-white.import:before {
    background-position: -13.2em -12.6em;
    width: 1.8em;
    height: 1.8em;
}

.icon-white.lock:before {
    background-position: -15em -14.4em;
    width: 1.6em;
    height: 2.2em;
}

.icon-white.menu:before {
    background-position: -16.6em -16.6em;
    width: 2em;
    height: 1.2em;
}

.icon-white.plus:before {
    background-position: -18.6em -17.8em;
    width: 1.8em;
    height: 1.8em;
}

.icon-white.search:before {
    background-position: -20.4em -19.6em;
    width: 1.8em;
    height: 1.8em;
}

.icon-white.settings:before {
    background-position: -22.2em -21.4em;
    width: 1.8em;
    height: 1.8em;
}

.icon-white.support:before {
    background-position: -24em -23.2em;
    width: 2em;
    height: 1.8em;
}

