.agreement-button-block {
    display: flex;
    justify-content: space-between;

    // border: 1px solid red;
    button {
        // width: 30%;
    }
}

.agreement-label {
    font-weight: 400;
}

.agreement-status-label {
    width: 100px;
    height: 100px;
    display: block;
    position: fixed;
    top: 20%;
    left: 0;
    color: red;
    border: 2px dotted green;
}

.contract-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        width: 33%;
    }
}

.align-center {
    text-align: center;
}

.nds-block {
    width: 75px !important;
}

// todo datepicker styles move to component
.date-block {
    padding: 0 20px;

    .date {
        font-size: 16px;
    }
}