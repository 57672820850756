@media print {
  .md-dialog-is-showing .main-view {
    display: none;
  }
  .md-dialog-container {
    z-index: 100;
  }
  md-dialog.md-dialog-fullscreen {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0;
    .nt-modal__body {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background: white;
    }
    .nt-modal__footer {
      display: none;
    }
  }
}