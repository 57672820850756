.tender-view-documents-wrapper {
  .no-documents {
    padding-bottom: 40px;
  }
  .row {
    padding: 20px 0px;
    border-bottom: 1px solid #eaebeb;
  }
  .list {
    .title {
      font-size: 18px;
      color: $text-dark-gray;
      word-break: break-all;

      a {
        color: $text-dark-gray;
        .tenderer {
          font-size: 16px;
          color: $text-carrot;
        }
      }


    }
    .details {
      font-size: 14px;

      span.type {
        color: $btn-default-bg;
      }

      &.modified {
        color: $text-carrot;
      }
    }
  }

  .actions {
    text-align: right;

    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .version {
    padding: 20px 0px;

    .title {
      text-decoration: line-through;
    }

    .details {
      color: $text-dark-gray;
    }
  }
}

.md-tooltip-fix {
    width: 400px !important;
}
