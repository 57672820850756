.tender-view-chat-wrapper {
    .can-not-ask {
        margin-bottom: 10px;
    }
    .question-btn{
        margin-bottom: 10px;
    }

    .question-container {
        background-color: #F4F4F4;
        padding: 5px 5px 10px;
        cursor: pointer;
        border-top: 1px solid #dbdbdb;
        border-bottom: 1px solid #dbdbdb;

        @media (max-width: $content-md-width) {
            margin-left: -5px;
            margin-right: -5px;
        }

        &.changes {
            max-width: 800px;

            .long-text-break {
                word-wrap: break-word;
            }
        }
        .user{
            margin-bottom: 0;
        }

        &.odd {
            background-color: #fff;
        }

        .icon-quest {
            background-color: #3FAA98;
            height: 50px;
            width: 50px;
            border-radius: 30px;

            .icon {
                position: relative;
                top: 7.5px;
                left: 15px;
            }
        }

        .user {
            color: #000;
        }

        .date {
            color: #B0B0B0;
        }

        .question-title {
            color: #BF5050;
            font-weight: bold;
            font-style: italic;
        }

        .question-description {
            color: #000;
            font-weight: bold;
            font-style: italic;
            word-wrap: break-word;
        }

        .answer {
            opacity: 0;
            //display: none;
            background-color: #20272D;
            height: 50px;
            width: 50px;
            border-radius: 30px;

            .icon {
                position: relative;
                top: 7.5px;
                left: 15px;
            }
        }

        &:hover {

            .answer {
                opacity: 1;
            }
        }
    }
    .answer-container {
        padding-left: 30px;
        .row {
            margin: 0;
        }
        @media (max-width: $content-md-width) {
            padding-left: 40px;
        }
        .answer {
            background-color: #ECECEC;
            padding: 10px 20px 20px;
        }
        .answer-title {
            color: #60B6A8;
            font-style: italic;
            font-weight: bold;
        }
        .answer-description {
            color: #000;
            font-style: italic;
            font-weight: bold;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }
}