@import "sprites-red";
@import "sprites-white";
@import "sprites-black";
@import "icomoon";

.icon:before {
  background-size: auto;
}

.icon.icon-newtend:before {
  content:' ';
  vertical-align:middle;
  display: inline-block;
  font-size: 10px;
  background-image: url("/static/images/newtend_ico.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 2em;
  height: 2em;
}