body {
    position: initial;
    top: initial;
    overflow: initial;
}

html {
    overflow: initial;
}

.content-form {
    md-input-container {
        margin: 15px 0 0 0;
        transition: margin cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s;
        .md-input {
            padding-left: 9px;
            padding-top: 0px;
        }
        .md-errors-spacer {
            display: none;
        }
        label:not(.md-no-float):not(.md-container-ignore) {
            transform: translate3d(9px, 37px, 0) scale(1);
            font-weight: normal;
        }
        textarea,
        input,
        md-select-value {
            border-right-width: 1px !important;
            border-left-width: 1px !important;
            border-top-width: 1px !important;
            height: 40px !important;
            border-radius: 2px !important;
            border-style: solid;
            padding-left: 8px !important;
            border-color: rgba(0, 0, 0, 0.12);
        }
        md-select-value {
            height: auto !important;
            min-height: 36px !important;
            >span:not(.md-select-icon) {
                white-space: normal !important;
            }
        }
        input[disabled],
        md-select[disabled] .md-select-value {
            background-image: none;
            border-bottom-width: 1px;
            border-bottom-color: #e1e1e1;
            background: #f1f1f1;
        }
        &.md-input-focused,
        &.md-input-has-value {
            margin-top: 30px !important;
        }
        &.md-input-has-placeholder.md-input-focused,
        &.md-input-has-placeholder.md-input-has-value {
            margin-top: 0px !important;
        }
        &.md-input-focused,
        &.md-input-has-placeholder,
        &.md-input-has-value {
            label:not(.md-no-float) {
                transform: translate3d(0, 6px, 0) scale(0.75);
                transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s;
            }
        }
        &.md-input-focused md-select-value {
            border-color: rgb(63, 170, 152);
        }
        &.md-input-invalid md-select-value {
            border-color: rgb(213, 0, 0);
        }
    }
    margin-bottom: 20px;
}

.top-login-form {
    md-input-container {
        margin: 4px 0;
    }
    .md-button,
    .md-button.md-raised {
        margin: 0 2px;
        padding: 8px;
    }
}

md-checkbox.md-checked .md-icon {
    background-color: #3faa98 !important;
}

md-checkbox {
    .md-label a {
        color: #616161;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.md-input-invalid md-checkbox {
    .md-label,
    .md-label a {
        color: #d50000;
    }
    .md-icon {
        border-color: #d50000;
    }
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
    margin-top: 70px;
    max-height: 160px !important;
    z-index: 3001;
}

md-backdrop.md-menu-backdrop {
    z-index: 110;
}

.md-tab {
    border-bottom: 2px solid #e2e2e2;
}

md-tabs md-ink-bar {
    color: #3faa98;
    background-color: #3faa98;
}

md-input-container .md-input-messages-animation .md-input-message-animation:not(.ng-animate) {
    margin-top: 3px;
    opacity: 1;
}

.md-button-200 {
    min-width: 200px;
}

.md-tooltip {
    left: 1323px;
    z-index: 101;
    top: 435px;
    max-width: 550px;
    word-wrap: normal;
    white-space: normal;
    height: auto;
    text-align: center;
    font-size: 13px;
    font-weight: normal;
    padding: 5px;
    line-height: 18px;
    overflow: visible;
    @media (max-width: $content-md-width) {
        display: none;
    }
    &.md-origin-top:after {
        display: block;
        content: ' ';
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: -5px;
        left: 50%;
        margin-left: -5px;
        transform: rotate(45deg);
        background-color: rgb(97, 97, 97);
    }
}

header .md-toolbar-tools {
    @media (max-width: $content-md-width) {
        padding: 0;
    }
}

md-content {
    overflow: visible;
}