@font-face {
  font-family: 'icomoon';
  src:	url('/static/fonts/icomoon/icomoon.eot?pij0oq');
  src:	url('/static/fonts/icomoon/icomoon.eot?pij0oq#iefix') format('embedded-opentype'),
  url('/static/fonts/icomoon/icomoon.ttf?pij0oq') format('truetype'),
  url('/static/fonts/icomoon/icomoon.woff?pij0oq') format('woff'),
  url('/static/fonts/icomoon/icomoon.svg?pij0oq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.list-icon {
  width: 10px;
  height: 14px;
  margin-right: 10px;
  display: inline-block;
  color: #999999;
  vertical-align: bottom;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-questions:before {
  content: "\e901";
}

.icon-documents:before {
  content: "\e904";
}

.icon-items:before {
  content: "\e900";
}

.icon-qualification:before {
  content: "\e902";
}

.icon-bids:before {
  content: "\e903";
}