.app-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    &__bg {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: white;
        opacity: 0.5;
    }
    &__circle {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -48px;
        margin-top: -48px;
        path {
            stroke-width: 3px !important;
        }
    }
    &__letter {
        width: 34px;
        height: 34px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -17px;
        margin-top: -17px;
        z-index: 3;
        font-size: 34px;
        text-align: center;
        line-height: 34px;
        color: #3faa98;
    }
}

.transformed {
    transform: rotate3d(15, 50, 0, 60deg);
    background-color: blue;
}
