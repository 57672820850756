@import "../../node_modules/compass-sass-mixins/lib/compass/css3/_opacity.sass";

// modal backdrop is broken in angular-ui, see https://github.com/angular-ui/bootstrap/issues/2970
.modal-backdrop {
  bottom: 0;
}
.modal-backdrop.in {
  @include opacity(0.3);
}

.modal-header {
  padding: 20px;
  color: #fff;
  font-size: 18px;
  background-color: #3FAA98;

  &.blank-header {
    background: none;
    border: none;
    text-align: right;
  }
}

.modal-body {
  padding: 0;
  @media (max-width: $content-md-width) {
    width: auto !important;
  }
  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .contenteditable {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    resize: none;
    width: 100%;
    outline: none;
    display: block;
    background-color: #fff;
  }

  .md-button {
    margin: 10px 0;
  }

  .form {
    height: 200px;
    padding: 25px;
  }

}

.modal-footer {
  padding: 0px;

  .btn + .btn {
    margin: 0px;
  }

  .btn {
    width: 50%;
    float: left;
  }

  .single-btn {
    width: 100%;
  }
}

.center-modal {
  padding-top: 7.5%;
}

.nt-modal {
  &__body {
    width: 600px;
    padding: 10px;
    min-height: 200px;
  }
  &__footer {
    padding-right: 18px;
    padding-left: 0;
  }
}