.planning-overview-wrapper {
  width: 800px;

  a {
    cursor: pointer;
    color: #000;
  }

  .row {
    margin: 0;

    .customer {
      text-decoration: underline;
      cursor: pointer;
      font-size: large;

      &:hover {
        color: $text-cyan;
      }
    }

    .form-control {
      border-top: 1px solid #CACBCC;
    }

    &.top-fields {
      margin: 10px 0 0 0;

      .form-control {
        border-top: none;
      }
    }
    &.buttons {
      margin: 20px 0;

      .publish {
        padding-left: 0;
      }

      #edit-tender-btn {
        margin-left: 20px;
      }
    }

  }

  label {
    font-weight: normal;
    cursor: pointer;
  }

  .input-icon {
    position: absolute;
    right: 30px;
    top: 25px;
  }

  .form-control {
    padding: 20px 10px;
    height: auto;
    font-size: 18px;
    box-shadow: none;
    border: none;
  }

  .list {
    margin-top: 60px;

    .row {
      padding: 20px 0px;
      border-top: 1px solid #eaebeb;

      .version {

        .title {
          text-decoration: line-through;
        }


      }

      .title {
        font-size: 20px;
        color: $text-dark-gray;

        a {
          color: $text-dark-gray;
        }
      }
      .details {
        font-size: 14px;

        &.modified {
          color: $text-carrot;
        }
      }
    }
  }
}