.customer-modal-wrapper {

  .dropdown {

    .dropdown-toggle {
      // color: #666666;;
      font-size: 15px;
      cursor: pointer;
    }

    .dropdown-menu {

      a {
        cursor: pointer;
      }
    }
  }

  .dark-top-border {
    border-top: 1px solid #dddddd;
  }

  .bold-font {
    font-size: 22px;
    font-weight: bold;
  }

  .bold-label {
    font-size: 14px;
    font-weight: bold;
  }

  label {
    // color: #ccc;
  }

  .row {
    padding: 10px;
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
  }

  .close-cross {
    cursor: pointer;
  }
}