@media (min-width: $screen-sm-min) {
    .row {
        &.v-align {
            @include display-flex();
            @include align-items(center);
        }
    }
}

.container,
.container-fluid {
    // padding: 0;
}

.modal-body {
    .row {
        margin: 0;
    }
}