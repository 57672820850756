.breadcrumb {
    padding: 0;
    margin: 0;
    @media (max-width: $content-md-width) {
    }
    li {
        @include transition-duration(0s);
    }
    a,
    :before {
        color: $breadcrumb-default-color !important;
    }
}