.bids-modal {

  .modal-body {

    .form {
      border-bottom: 2px solid #20272d;
    }

    .documents-block {
      padding: 20px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .file-name {
        max-width: 50%;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        top: 3.5px;
      }

      .attach {
        cursor: pointer;
        display: inline-block;
        margin-right: 25px;
        padding: 5px;

        .attach-title {
          font-size: 16px;
          position: relative;
          top: 3px;
          border-bottom: dashed 1px;
          color: #3FAA98;
        }
      }

      .document {
        position: relative;
        margin-right: 10px;
        bottom: 6.5px;
      }

      &.list-documents {
        .document {
          display: block;
          padding-top: 10px;
        }
      }
    }

    .has-error .form-control {
      border-bottom: 2px solid $validation-border-color;
      background-color: $validation-background-color;
    }
  }
}