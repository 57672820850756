.icon-red {
    font-size: 10px;
}

.icon-red:before {
    content: ' ';
    vertical-align: middle;
    display: inline-block;
    background-image: url("/static/images/spriteRed.svg");
    background-repeat: no-repeat;
    background-size: 4em 4em;
}

.no-svg .icon-red:before {
    background-image: url("/static/images/spriteRed.png");
}

.icon-red.checkbox-on:before {
    background-position: 0em 0em;
    width: 2em;
    height: 2em;
}

.icon-red.close-red:before {
    background-position: -2em -2em;
    width: 2em;
    height: 2em;
    position: relative;
    bottom: 1.5px; // kludge need to make with aligns
}

.top-aling.icon-red.close-red:before {
    vertical-align: top;
    bottom: 0;
}

.gutter-left.icon-red.close-red:before {
    margin-left: 5px;
}