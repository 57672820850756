.sidebar-wrapper {
  position: relative;
  @include flex(0 0 320px);
  @include display-flex();
  @include flex-direction(row);

  button {
    width: auto !important;
  }

  .make-bid {
    position: absolute;
    bottom: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    padding-right: 40px;

    .cancel-bid {
      color: #c35c5c;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
      margin-bottom: 20px;
    }

    span {
      color: #bfbfbf;
      font-size: 14px;
      display: inline-block;

      //&.title {
      //  margin-top: 20px;
      //}

      &.date {
        margin-bottom: 20px;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
  }

  .info {
    position: absolute;
    bottom: 20px;
    text-decoration: none;
  }
}

.sidebar {
  font-size: 17px;
  margin: 40px 0 0 40px;

  .nav {
  text-decoration: underline;

    a {
      cursor: pointer;
      color: #000;
      padding-left: 0 !important;

      &:hover {
        background-color: #fff;
        color: #6fbfb2;
      }

      &:focus {
        background-color: #fff;
        color: #6fbfb2;
      }

      &:active {
        background-color: #fff;
        color: #6fbfb2;
      }

      &.active {
        background-color: #fff;
        color: $active-state-color;
      }
    }
  }

  .sidebar-profile {

    .bottom-block {
      position: absolute;
      bottom: 0px;
      width: 100%;

      .make-complain {
        color: #c35c5c;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;
        position: relative;
        bottom: 20px;
      }

      .make-bid {
        position: relative;

        .auction-link {
          color: #20272d;
          text-decoration: underline;
        }
      }

      .info {
        position: relative;
        padding-right: 40px;
      }
    }

  }
}