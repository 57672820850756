.pagination-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    @media (min-width: $content-sm-width) {
        text-align: right;
    }
    .total {
        color: $text-light-gray;
        min-width: 140px;
        display: inline-block;
        @media (max-width: $content-md-width) {
            display: inline-block;
            margin: 5px 10px;
        }
    }

}

.pagination>li>a,
.pagination>li>span {
    padding: 6px 10px;
    margin-left: 0;
}

.pager,
.pagination {
    margin: 10px;

    @media (max-width: $content-md-width) {
        margin-left: 0;
    }

    button,
    a {
        padding: 5px 10px;
        background-color: $btn-square-bg;
        border: none !important;
        color: #fff;

        &.active {
            color: $active-state-color;
        }
    }

    li.back-link a,
    li.forward-link a {
        color: white;
        background-color: $btn-square-bg;
    }

    li.back-link button,
    li.forward-link button {
        color: white;
        border-radius: 2px;
        background-color: $btn-square-bg;
    }

    li {

        [disabled="disabled"] {
            background-color: #dbdbdb !important;
        }
    }

    .back-link {
        float: left;
    }
}