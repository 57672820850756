.classifier {
    background-color: #fff;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2000;
    width: 620px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    @media (max-width: $content-md-width) {
        width: auto;
        left: 0;
    }
    .input-wrapper {
        height: 80px;
        display: block;
        background-color: #fff;
        border-bottom: 2px solid #20272D;
        input {
            border: 0;
            margin: 10px 0;
            height: 58px;
            padding: 9px;
            outline: none;
            width: 88%;
        }
        .btn-default {
            position: absolute;
            top: 0;
            right: 0;
            height: 80px;
            width: 80px;
        }
    }
    .tree {
        overflow-y: auto;
        position: absolute;
        top: 80px;
        right: 0;
        left: 0;
        bottom: 61px;
        .tree-collapse {
            display: none;
        }
        .tree-item {
            margin-left: 25px;
        }
        .row {
            height: auto;
            background-color: #fff;
            margin: 0;
            &.v-align {
                border-bottom: 1px solid #E8E9EA;
            }
            .icons {
                cursor: pointer;
                display: inline-block;
                padding: 0 10px;
                .toggle-wrapper {
                    span {
                        height: 20px;
                        width: 20px;
                        display: inline-block;
                        position: relative;
                        bottom: 5px;
                        margin-right: 5px;
                    }
                }
                input {
                    margin-left: 10px;
                }
            }
            .description {
                display: inline-block;
                width: 80%;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
                .code {
                    font-weight: bold;
                }
            }
            .checkbox {
                margin-left: 0;
            }
        }
    }
    .btn-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        .cancel {
            width: 35%;
        }
        .choose {
            width: 65%;
        }
    }
}

.classifiers-list-form {
    padding: 10px;
}

.back-arrow {
    font-size: 24px;
    margin-top: -8px;
    display: block;
}

.classifiers-list {
    &__item {
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
        &:last-child {
            border-bottom: none;
        }
        .tree-collapse {
            margin-left: 5px;
        }
    }
    &-form__search {
        margin-bottom: 10px;
        md-input-container {
            margin-top: 0 !important;
        }
    }
    .classifiers-list {
        margin-left: 25px;
    }
}