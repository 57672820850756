.icon-black {
    font-size: 10px;
    &.icon-save{
        background: transparent;
        padding: 0;
        border: none;
        margin-right: 10px;
    }
}

.icon-black:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("/static/images/spriteBlack.svg");
    background-repeat: no-repeat;
    background-size: 17em 15.4em;
}

.no-svg .icon-black:before {
    background-image: url("server/apps/openprocurement/static/images/spriteBlack.svg");
}

.icon-black.attach:before {
    background-position: 0em 0em;
    width: 2.8em;
    height: 1.4em;
    margin-right: 0.5em;
}

.icon-black.calendar:before {
    background-position: -2.8em -1.4em;
    width: 1.8em;
    height: 1.8em;
}

.icon-black.checkbox-off:before {
    background-position: -4.6em -3.2em;
    width: 2em;
    height: 2em;
}

.icon-black.class-minus:before {
    background-position: -6.6em -5.2em;
    border-radius: 2px;
    width: 2em;
    height: 2em;
}

.icon-black.class-plus:before {
    background-position: -8.6em -7.2em;
    border-radius: 2px;
    width: 2em;
    height: 2em;
}

.icon-black.icon-save:before {
    background-position: center;
    width: 1.8em;
    height: 1.8em;
    background-image: url(/static/images/icon/black/save.svg);
    background-size: contain;
}

.icon-black.close-cross-black:before {
    background-position: -10.6em -9.2em;
    width: 1.8em;
    height: 1.8em;
}

.icon-black.info:before {
    background-position: -12.4em -11em;
    width: 1.8em;
    height: 1.8em;
}

.icon-black.plus-black:before {
    background-position: -14.2em -12.8em;
    width: 1.8em;
    height: 1.8em;
}

.icon-black.select:before {
    background-position: -16em -14.6em;
    width: 1em;
    height: 0.8em;
}

