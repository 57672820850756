input.form-control,
textarea.form-control,
.form-control-dropdown {
    border-bottom: 1px solid $input-border-bottom;
    box-shadow: none !important;
    border-radius: 2px;
}

.form-control[readonly] {
    cursor: default;
    border-bottom: 1px solid $input-readonly-border-bottom;
    border-right: none;
    border-left: none;
    border-top: none;
}

.form-control {
    word-wrap: break-word;
}

.has-error .form-control {
    border: solid 1px $input-border-bottom;
    border-bottom: 2px solid $validation-border-color;
    background-color: $validation-background-color;
}

.has-error .dropdown {
    border-bottom: 2px solid $validation-border-color;
    background-color: $validation-background-color;
    padding-bottom: 18px;
}

.has-error .form-control:focus {
    border-color: $validation-focus-border-color;
    border-bottom-color: $validation-border-color;
    background-color: $input-bg;
}

.form-group {
    margin: 0;
}

.label-error-info {
    font-size: 11px;
    color: #993333;
}

.form-claim-satisfied {
    height: 32px;
}

span.dimensions {
    display: inline-block;
    padding: 18px 8px;
    line-height: 22px;
}

.amount-value-group {
    margin: 10px;
    padding: 0;
}

:required {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiAQEQDx3qPI2aAAAAwUlEQVQoz5WRsQqCUBSGPyx9ggbXiBAXp7Z2X8QewM1XUIjwBZI2B5/A6U6CQ0sQiISv0aAgDbdAb0l1lsM957v/Of+9MA4Pb1yYK8BaOaPxJTTAnOyaEkgp2Lw1NxSkEnDJyEkGSiYJORmuBDpiLFoqAgwMAipaLGK6saiDoKdH4PzhYvbMCw7sOXGl5MKRJSX3l4KOT42BTURLS4SNQY2PLm+Lgc2QcGBTyKfe0XwYfmbLSo5oJjdsfnCh/uZNBR6UWStKEWtd4gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOC0wMS0wMVQxNjoxNToyOSswMTowMM/8Z+IAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTgtMDEtMDFUMTY6MTU6MjkrMDE6MDC+od9eAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==");
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: calc(100% - 3px) 3px;
}

.ng-valid {
    background-image: url();
}

.input-icon-group {
    position: relative;

    &-inl-block {
        display: inline-block;
    }
}

// it is a kludge for validation
.noValidateDays {
    border: none !important;
    border-bottom: 1px solid #cacbcc !important;
    background-color: #eee !important;
}

.noValidateHours {
    input {
        border: none !important;
        border-bottom: 1px solid #cacbcc !important;
        background-color: #fafafa !important;
    }
}

.date-filter-form {
    .form-control {
    }
}