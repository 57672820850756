.tender-view-prequalification-wrapper {
  @media (max-width: $content-md-width) {
    margin-top: 0px;
    padding: 0 10px;
  }
  h4 {
    color: #3faa98;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    word-break: break-all;

    &.state {
      color: #f44336;
    }
  }

  .comment {
    color: #2196F3;
  }

  .label-field {
    color: #3faa98;
    font-weight: bold;
  }

  hr {
    border: none;
    border-bottom: 1px solid #f0f1f1;
    margin-top: 30px !important;
  }

  .cancellation-block {
    padding-top: 15px;

    h4 {
      color: #ba4f4f;
      font-weight: bold;
    }


    .row {
      margin-top: 15px;
    }

    .list {

      .row {
        margin-top: 0;
      }

      .col-xs-2 {
        padding: 0;

        a {
          margin-top: 10px;
        }
      }

      .btn {
        padding-top: 15px;
      }

      .col-sm-8 {
        margin-top: 5px;

        a {
          color: black;
          font-size: 16px;
        }
      }
    }

  }

  .auction-members-block {
    margin-bottom: 35px;

    .row {
      background-color: #f4f4f4;
      padding: 20px;

      &:nth-child(odd) {
        background-color: #f4f4f4;
      }

      &:nth-child(even) {
        background-color: #fff;
      }

      .active {
        color: #83c6bb;
      }

      .canceled {
        color: #c76a6a;
      }

      .default {
        color: #000;
      }

      &:last-child {
        border-bottom: 1px solid #e1e1e1;
      }

      .price {
        font-size: 35px;
        font-weight: bold;
        color: #000;
      }

      .name {
        font-size: 18px;
        text-decoration: underline;
        color: #000;
      }
    }

    .qualification {
      hr {
        border: none;
        border-bottom: 1px solid #f0f1f1;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
    }

    .actions-wrapper {
      margin-top: 20px;
    }
  }
}
.criteria-active-block {
  border: 2px solid #3faa98;
  margin: 10px 0;
  padding: 10px;
}