.header {
    height: 40px;
    background-color: $header-bg-color;
    padding: 8px 10px;
    z-index: 80;
    font-size: 15px;
    @include display-flex(flex);
    @include justify-content(space-between);
    @include align-items(center);

    @media (max-width: $content-md-width) {
        flex-direction: column;
        padding: 0 10px;
        font-size: 10px;
    }

    h4 {
        padding-left: 15px;
        color: #FFFFFF;
        margin: 3px 0 0 0;
    }

    .title {
        color: #FFFFFF;
        margin: 2px 0 0 0;
        padding: 0 15px;

        @media (max-width: $content-md-width) {
            width: 100%;
            padding: 0 10px;
            font-size: 10px;
            margin: 0;
        }

        @media (max-width: $content-lg-width) {
            font-size: 13px;
        }
    }

    .right {
        margin-left: auto;

        @media (max-width: $content-md-width) {
            padding: 0 10px;
            width: 100%;
        }

        a {
            margin: 0;
        }
    }

    .left-block {
        @media (max-width: $content-md-width) {
            width: 100%;
        }

        #menu .dropdown-menu {
            margin-top: 10px;
            z-index: 10000;
        }
    }

    .right-block {

        >a {
            margin-right: 0;

            &.btn {
                text-decoration: none;
                padding: 0px 12px;
                border-radius: 2px;
            }

            &.btn-login {
                color: #000000;
                background-color: #FFFFFF;
                border-color: #FFFFFF;
                margin-left: 0;

                &:hover {
                    background-color: #cccccc;
                    border-color: #cccccc;
                }
            }

            &:last-child {
                margin-right: 40px;
                margin-left: 10px;
            }

        }

    }

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &.active {
            color: $active-state-color;
        }

        .glyphicon {
            font-size: 16px;
            padding-left: 4px;
            top: 2px;
        }
    }

    .logo {
        position: absolute;
        left: 43%;
        width: 14%;
        top: -52%;
        display: block;
        min-height: 81px;
        background-image: url("/static/images/logo.svg");
        background-repeat: no-repeat;
        background-position: 0 center;
        background-size: 100%;
        cursor: pointer;
    }

    &.header-fixed {
        position: fixed;
        top: $header-height;
        left: 0;
        right: 0;
        padding: 0 30px;
        z-index: 90;

        @media (max-width: $content-md-width) {
            padding: 0 15px;
        }
    }

    &.header-list {
        background: #edf7f5;
    }
}

.header-menu {
    >button {
        @media (max-width: $content-md-width) {
            margin: 0;
        }
    }

    .header-menu__group {
        margin-top: 5px;
    }
}

.header-menu__content {
    max-height: 600px;
    min-width: 180px;
}

.menu-group {
    &__content {
        margin: 5px 0;
    }

    &__icon {
        padding: 0 10px;
    }

    &__items {
        border-left: 1px solid #d2d2d2;

        span {
            display: block;
            position: relative;
            width: 120px;
            line-height: 44px;

            i {
                position: absolute;
                right: 0;
                color: grey;
                font-size: 14px;
                top: 14px;
            }
        }
    }
}

.header-tools {
    &__button {
        padding: 5px 10px !important;
    }
}

.dialog-form {
    padding-left: 10px;
    margin-bottom: 0 !important;
    padding-right: 10px;
    margin-bottom: 0 !important;
    min-width: 300px;

    &__link a {
        padding-left: 3px;
        color: #3faa98;
        position: relative;
        top: -3px;
        text-align: right;
        color: #3faa98;
    }
}

md-input-container.md-input-has-placeholder {
    margin: 0 !important;
}

md-menu-content {
    padding-bottom: 0;
}

.md-select-menu-container {
    z-index: 3100;
}

.impersonate {
    z-index: 1000;
    display: block;
    position: fixed;
    top: 90px;
    left: 10px;
    text-align: center;

    &__link {
        color: #fff;
        width: 145px;
        display: block;
        box-shadow: 0 0 7px #5f5f5f;
        padding: 10px 2px;
        background-color: #ff9c00;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;

        i {
            font-size: 11px;
            margin-left: 5px;
        }

        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #de8904;
        }
    }
}



.cdb-test-mode {
    background: #5bc0de;
    padding: 0px 10px;
    margin: 0px 10px;
    color: #ffffff;
    font-size: 13px;
    border-radius: 4px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: bold;

    &__info {
        position: absolute;
        color: #222;
        text-transform: none;
        overflow: hidden;
        transition: all 0.4s ease;
        text-align: left;
        height: 0;
        border-radius: 4px;
        box-shadow: 0 0 15px -5px rgba(0,0,0,0.4), 0 20px 25px -15px rgba(0,0,0,0.1);
        background: white;
        font-size: 14px;
        top: 32px;
        left: -250px;
        width: 320px;
        white-space: normal;
        line-height: 21px;
        padding: 15px;
        font-weight: normal;
        display: none;
    }

    &:hover &__info {
        height: 220px;
        display: block;
    }
}

.bid-btns {
    .layout-column {
        min-height: 40px;
    }
}